<template>
    <div>
        <p><strong>Are You Sure?</strong></p>
        <button class="btn btn-lg btn-danger" @click="removePromotion($event)">REMOVE PROMOTION</button>
    </div>
</template>
<script>
    import httpEnneathought from '../../http/Enneathought';
    export default{
        props: ['promotionId'],
        methods: {
            removePromotion(e) {
                e.target.disabled = true;
                httpEnneathought.deletePromotion(this.promotionId).then(() => {
                    this.$emit('promotion-removed');
                }).catch(() => alert('Error Removing Promotion'));
            }
        }
    }
</script>
