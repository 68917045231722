<template>
    <div>
        <modal :show="showEditForm" @close="showEditForm = false;">
            <div slot="header">
                <p class="h2">Edit Subscriber</p>
            </div>
            <edit-subscriber-form slot="body" :subscriber="subscriber" @subscriber-updated="updateSubscriber($event)"></edit-subscriber-form>
        </modal>
        <modal :show="showNotificationDetails" @close="showNotificationDetails = false;">
            <div slot="header">
                <p class="h2">Notification Details</p>
            </div>
            <notification-details slot="body" :notification="activeNotification" />
        </modal>
        <modal :show="showBounceDetails" @close="showBounceDetails = false;">
            <div slot="header">
                <p class="h2">Bounce Details</p>
            </div>
            <bounce-details slot="body" :bounce-id="activeBounceId" />
        </modal>
        <div v-if="subscriber">
            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-sm btn-warning" @click="showEditForm = true;">Edit</button>
                    <button class="btn btn-sm" @click="toggleSubscribed" :class="[subscriber.unsubscribed_at === null ? 'btn-danger' : 'btn-success']">
                        {{ subscriber.unsubscribed_at === null ? 'Unsubscribe' : 'Subscribe' }}
                    </button>
                    <p class="h2">Subscriber Details</p>
                    <ul>
                        <li><strong>First Name:</strong> {{ subscriber.first_name }} </li>
                        <li><strong>Last Name:</strong> {{ subscriber.last_name }}</li>
                        <li><strong>E-Mail:</strong> {{ subscriber.email }}</li>
                        <li><strong>Time Zone:</strong> {{ subscriber.time_zone }}</li>
                        <li><strong>Created At:</strong> {{ subscriber.created_at }}</li>
                        <li><strong>Status:</strong> {{ subscriber.unsubscribed_at === null ? "Subscribed" : "Unsubscribed: " }} {{ subscriber.unsubscribed_reason }}</li>
                    </ul>
                    <p class="h2">Subscriptions</p>
                    <ul>
                        <li v-for="subscription in subscriber.subscriptions">
                            <strong>{{ baseClass(subscription.model) }} - {{ subscription.subject.name }}</strong>
                            <ul>
                                <li>Subscribed At: {{ subscription.created_at }}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <strong>Total Sends:</strong> {{ subscriber.notifications.length }}
                        </li>
                        <li>
                            <strong>Total Total Opens:</strong> {{ readNotificationCount }}
                        </li>
                        <li>
                            <strong>Open Rate:</strong>
                            {{ Math.floor(((readNotificationCount / subscriber.notifications.length) * 100)) + '%' }}
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6">
                    <p class="h2">Notifications</p>
                    <div class="row" style="margin: 10px;">
                        <div class="col-sm-5">
                            <label>Begin Date</label>
                            <input class="form-control" type="date" v-model="notificationBeginDate" />
                        </div>
                        <div class="col-sm-5">
                            <label>End Date</label>
                            <input class="form-control" type="date" v-model="notificationEndDate" />
                        </div>
                    </div>
                    <div class="row" style="margin: 10px;">
                        <div class="col-sm-3">
                            <input type="checkbox" v-model="showReadNotifications">
                            <label>Read</label>
                        </div>
                        <div class="col-sm-3">
                            <input type="checkbox" v-model="showUnReadNotifications">
                            <label>Unread</label>
                        </div>
                    </div>
                    <div class="list-group">
                        <paginate
                                name="notifications"
                                :list="filteredNotifications"
                                :per="10"
                        >
                            <button v-for="notification in paginated('notifications')"
                                    :class="['list-group-item', notification.read_at !== null ? 'list-group-item-success' : 'list-group-item-default']"
                                    @click="openNotificationDetails(notification)">
                                {{ notification.created_at }} -
                                {{ baseClass(notification.type) }}
                                <span class="badge">
                                    {{ notification.read_at !== null ? 'READ' : 'UNREAD' }}
                                </span>
                            </button>
                        </paginate>
                    </div>
                    <paginate-links for="notifications"
                                    :classes="{'ul' : 'pagination'}"
                    >
                    </paginate-links>
                </div>
                <div class="col-sm-6">
                    <p class="h2">Bounces</p>
                    <div class="row" style="margin: 10px;">
                        <div class="col-sm-5">
                            <label>Begin Date</label>
                            <input class="form-control" type="date" v-model="bounceBeginDate" />
                        </div>
                        <div class="col-sm-5">
                            <label>End Date</label>
                            <input class="form-control" type="date" v-model="bounceEndDate" />
                        </div>
                    </div>
                    <div class="row" style="margin: 10px;">
                        <div class="col-sm-3">
                            <input type="checkbox" v-model="showHardBounces">
                            <label>Hard</label>
                        </div>
                        <div class="col-sm-3">
                            <input type="checkbox" v-model="showSoftBounces">
                            <label>Soft</label>
                        </div>
                        <div class="col-sm-3">
                            <input type="checkbox" v-model="showUndeterminedBounces">
                            <label>Undetermined</label>
                        </div>
                        <div class="col-sm-3">
                            <input type="checkbox" v-model="showAbuseBounces">
                            <label>Abuse</label>
                        </div>
                    </div>
                    <div class="list-group">
                        <paginate
                                name="bounces"
                                :list="filteredBounces"
                                :per="10"
                        >
                            <button v-for="bounce in paginated('bounces')"
                                    :class="['list-group-item', bounce.bounce_type === 'Permanent' ? 'list-group-item-danger' : bounce.bounce_type == 'Undetermined' ? 'list-group-item-default' : 'list-group-item-warning']"
                                    @click="openBounceDetails(bounce)">

                                {{ bounce.created_at }} {{ bounce.notification_type }} {{ bounce.bounce_type }}
                            </button>
                        </paginate>
                    </div>
                    <paginate-links for="bounces"
                                    :classes="{'ul' : 'pagination'}"
                    >
                    </paginate-links>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .box{
        background-color: white;
        padding: 50px;
        margin: 25px;
    }
</style>
<script>
    import Modal from './Modal.vue';
    import EditSubscriberForm from './EditSubscriberForm.vue';
    import NotificationDetails from './NotificationDetails.vue';
    import BounceDetails from './BounceDetails.vue';
    import httpEnneathought from '../../http/Enneathought';
    export default{
        data(){
            return{
                subscriber: null,
                showEditForm: false,
                showNotificationDetails: false,
                showBounceDetails: false,
                paginate: ['notifications', 'bounces'],
                activeNotification: null,
                activeBounceId: null,
                showReadNotifications: true,
                showUnReadNotifications: true,
                notificationBeginDate: null,
                notificationEndDate: null,
                showHardBounces: true,
                showSoftBounces: true,
                showUndeterminedBounces: true,
                showAbuseBounces: true,
                bounceBeginDate: null,
                bounceEndDate: null

            }
        },
        computed: {
            readNotificationCount() {
                if(this.subscriber) {
                    return this.subscriber.notifications.filter(notification => notification.read_at !== null).length;
                }else {
                    return 0;
                }
            },
            filteredNotifications() {
                console.log(this.subscriber.notifications);
                let notifications = this.subscriber.notifications;

                if(!this.showReadNotifications) {
                    notifications = notifications.filter(notification => notification.read_at === null);
                }

                if(!this.showUnReadNotifications) {
                    notifications = notifications.filter(notification => notification.read_at !== null);
                }

                if(this.notificationBeginDate) {
                    notifications = notifications.filter(notification => new Date(notification.created_at) >= new Date(this.notificationBeginDate));
                }

                if(this.notificationEndDate) {
                    notifications = notifications.filter(notification => new Date(notification.created_at) <= new Date(this.notificationEndDate));
                }

                return notifications;
            },
            filteredBounces() {
                let bounces = this.subscriber.bounces;

                if(!this.showHardBounces) {
                    bounces = bounces.filter(bounce => bounce.bounce_type !== 'Permanent');
                }

                if(!this.showSoftBounces) {
                    bounces = bounces.filter(bounce => bounce.bounce_type !== 'Transient');
                }

                if(!this.showUndeterminedBounces) {
                    bounces = bounces.filter(bounce => bounce.bounce_type !== 'Undetermined');
                }

                if(!this.showAbuseBounces) {
                    bounces = bounces.filter(bounce => bounce.bounce_type !== 'abuse');
                }

                if(this.bounceBeginDate) {
                    bounces = bounces.filter(bounce => new Date(bounce.created_at) >= new Date(this.bounceBeginDate));
                }

                if(this.bounceEndDate) {
                    bounces = bounces.filter(bounce => new Date(bounce.created_at) <= new Date(this.bounceEndDate));
                }

                return bounces;
            }
        },
        props: ['subscriberId'],
        methods: {
            fetchSubscriber() {
                httpEnneathought.getSubscriber(this.subscriberId).then(({data}) => {
                     this.subscriber = data;
                });
            },
            baseClass(str) {
                let parsed = str.split('\\');
                return parsed[parsed.length -1];
            },
            updateSubscriber(subscriber) {
                this.subscriber = subscriber;
            },
            openNotificationDetails(notification) {
                this.activeNotification = notification;
                this.showNotificationDetails = true;
            },
            openBounceDetails(bounce) {
                this.activeBounceId = bounce.id;
                this.showBounceDetails = true;
            },
            toggleSubscribed() {
                if (this.subscriber.unsubscribed_at === null) {
                    httpEnneathought.unsubscribe(this.subscriber.obfuscated_id).then(({data}) => {
                        this.subscriber = data;
                    }).catch(() => {
                        alert('Could Not Unsubscribe');
                    });
                } else {
                    httpEnneathought.subscribe(this.subscriber.obfuscated_id).then(({data}) => {
                        this.subscriber = data;
                    }).catch(() => {
                        alert('Could Not Subscribe');
                    });
                }
            }
        },
        mounted() {
            this.fetchSubscriber();
        },
        components: {
            'modal': Modal,
            'edit-subscriber-form': EditSubscriberForm,
            'notification-details': NotificationDetails,
            'bounce-details': BounceDetails
        }
    }

</script>
