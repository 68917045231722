<template>
    <el-row>
      <el-col :sm="24" :md="12">
        <h5><u>Address You Entered</u></h5>
        <ul class="list">
          <li><b>Line 1:</b> {{ originalAddress.line_1 }}</li>
          <li v-if="originalAddress.line_2">
            <b>Line 2:</b> {{ originalAddress.line_2 }}
          </li>
          <li><b>City:</b> {{ originalAddress.city }}</li>
          <li><b>State/Province/Region:</b> {{ originalAddress.state }}</li>
          <li><b>Zip:</b> {{ originalAddress.zip }}</li>
          <li><b>Country:</b> {{ originalAddress.country }}</li>
        </ul>
      </el-col>
      <el-col :sm="24" :md="12">
        <div class="recommended">
          <h5><u>Recommended Address</u></h5>
          <ul class="list">
            <li><b>Line 1:</b> {{ validatedAddress.line_1 }}</li>
            <li><b>Line 2:</b> {{ validatedAddress.line_2 }}</li>
            <li><b>City:</b> {{ validatedAddress.city }}</li>
            <li><b>State/Province/Region:</b> {{ validatedAddress.state }}</li>
            <li><b>Zip:</b> {{ validatedAddress.zip }}</li>
            <li><b>Country:</b> {{ validatedAddress.country }}</li>
          </ul>
        </div>
      </el-col>
    </el-row>
</template>

<script>
export default {
  name: "AddressVerification.vue",
  props: {
    validatedAddress: Object,
    originalAddress: Object,
  },
};
</script>

<style scoped>
.list {
  list-style-type: none;
  padding: 0px;
}
.recommended {
  color: #000 !important;
}
</style>
