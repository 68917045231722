<template>
  <div>
    <label>Notification Type:</label>
    <el-select
      v-model="notificationType"
      placeholder="Select"
      @change="updateDisplayedContent"
      class="custom-width"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <partial-notification
      style="margin-top: 12px"
      @save-partial-notification="save"
      :notificationType="this.notificationType"
      :typesAndContents="this.typesAndContents"
    />
  </div>
</template>

<script>
import PartialNotification from "./PartialNotification.vue";

export default {
  components: {
    PartialNotification,
  },

  props: ["types", "typesAndContents"],

  data() {
    return {
      options: [],
      notificationType: "",
      loadedContent: "",
      id: 0,
    };
  },

  created() {
    // Populate dropdown options from types prop
    Object.keys(this.types).forEach((key) => {
      this.options.push({
        value: this.types[key],
        label: this.types[key],
      });
    });

    // If there's a query string, set the notification type based on it
    try {
      const url_string = window.location.href.toLowerCase();
      const url = new URL(url_string);
      const notificationType = url.searchParams.get("notification-type");
      this.notificationType =
        notificationType[0].toUpperCase() + notificationType.slice(1);
    } catch (error) {
      // If there is not a query string just set to the first notification type
      this.notificationType = this.options[0].value;
      console.log("Query string error: ", error);
    }

    this.updateDisplayedContent();
  },

  methods: {
    loadContent(notificationType, typesAndContents) {
      typesAndContents.forEach((pair) => {
        if (notificationType == pair["type"]) {
          this.loadedContent = pair["content"];
        }
      });
    },
    updateDisplayedContent() {
      this.loadContent(this.notificationType, this.typesAndContents);
    },
    reloadPageWithQueryString() {
      window.location.href = route("admin.partial-test-notification.index", {
        "notification-type": this.notificationType,
      });
    },
    save({ content, notificationType }) {
      if (this.loadedContent === "") {
        // Hit store route
        axios
          .post(route("admin.partial-test-notification.store"), {
            type: notificationType,
            content: content,
          })
          .then(() => {
            this.reloadPageWithQueryString();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // Hit update route
        const id = this.typesAndContents.filter(
          (pair) => pair.type === notificationType
        )[0]["id"];

        axios
          .patch(route("admin.partial-test-notification.update", { id }), {
            type: notificationType,
            content: content,
          })
          .then(() => {
            this.reloadPageWithQueryString();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.custom-width {
  width: 250px;
  padding-left: 5px;
}
</style>
