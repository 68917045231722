<template>
    <div
        class="modal"
        style="display: block"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
                type="button"
                class="close"
                @click="$emit('close')"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" :id="type + 'ModalLabel'">
              <slot name="header">
                default header
              </slot>
            </h4>
          </div>
          <div class="modal-body">
            <slot name="content">
              default header
            </slot>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
  .modal {
    overflow: scroll;
  }
  </style>

  <script>
    export default {
      name: "Modal",
      props: {
        type: String,
        header: String,
        content: String,
      }
    }
  </script>
