<template>
  <div
    class="panel panel-default business-panel"
    style="position: relative; height: auto"
  >
    <button
      @click="dismiss"
      id="business-panel-remove-panel"
      type="button"
      class="close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="panel-body">
      <h4 class="accessible-gold checkout-panel-title">Customer Account?</h4>
      <p>
        To purchase more than 20 test codes and/or administer tests for a group,
        please use a Customer Account for your purchase.
        <a href="https://www.enneagraminstitute.com/faqs/">Learn more.</a>
      </p>
      <p class="text-right">
        <a
          style="padding: 6px 20px"
          href="/business-login"
          class="btn btn-primary"
          >Create or Log In</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessAccount",
  methods: {
    dismiss() {
      this.$emit("dismiss-panel");
    },
  },
};
</script>
