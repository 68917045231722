<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="pull-right"><button class="btn btn-xs btn-primary" @click="fetchStats()">Refresh</button></div>
            <h3 class="panel-title">Sending Stats</h3>
        </div>
        <div class="panel-body">
            <ul>
                <li><strong>Number of Subscribers:</strong> {{ numberOfSubscribers }}</li>
                <li><strong>Total Sends:</strong> {{ totalSendsCount }}</li>
                <li><strong>Hard Bounce Count:</strong> {{ hardBounceCount }} ({{ hardBouncePercentage }})</li>
                <li><strong>Soft Bounce Count:</strong> {{ softBounceCount }} ({{ softBouncePercentage }})</li>
                <li><strong>Undetermined Bounce Count:</strong> {{ undeterminedBounceCount }} ({{ undeterminedBouncePercentage }})</li>
                <li><strong>Read Count:</strong> {{ readCount }} ({{ readCountPercentage }})</li>
            </ul>
        </div>
    </div>
</template>
<script>
    import enneathoughtHttp from '../../http/Enneathought';

    export default{
        data(){
            return{
                'numberOfSubscribers': 0,
                'totalSendsCount': 0,
                'hardBounceCount': 0,
                'softBounceCount': 0,
                'undeterminedBounceCount': 0,
                'readCount': 0
            }
        },
        methods: {
            fetchStats() {
                enneathoughtHttp.getSendingStats().then(({data}) => {
                    this.numberOfSubscribers = data.subscriber_count;
                    this.totalSendsCount = data.total_sends_count;
                    this.hardBounceCount = data.hard_bounce_count;
                    this.softBounceCount = data.soft_bounce_count;
                    this.undeterminedBounceCount = data.undetermined_bounce_count;
                    this.readCount = data.read_count;
                });
            }
        },
        computed: {
            hardBouncePercentage() {
                return Math.floor((this.hardBounceCount / this.totalSendsCount) * 100) + '%';
            },
            softBouncePercentage() {
                return Math.floor((this.softBounceCount / this.totalSendsCount) * 100) + '%';
            },
            undeterminedBouncePercentage() {
                return Math.floor((this.undeterminedBounceCount / this.totalSendsCount) * 100) + '%';
            },
            readCountPercentage() {
                return Math.floor((this.readCount / this.totalSendsCount) * 100) + '%';
            },
        },
        mounted() {
            this.fetchStats();
        }
    }
</script>
