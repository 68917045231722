<template>
    <div :class="['alert', 'alert-dismissible', status === 'success' ? 'alert-success' : 'alert-danger']" role="alert">
        <button type="button" class="close" @click="$emit('dismiss')" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        {{ message }}
    </div>
</template>
<script>
    export default{
        props: ['message', 'status']
    }
</script>
