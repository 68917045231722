<template>
    <div>
        <alert v-if="showAlert" :message="message" :status="status" @dismiss="showAlert = false;"/>
        <form @submit="updateSubscriber($event)">
            <div class="form-group">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="First Name" v-model="subscriber.first_name">
            </div>
            <div class="form-group">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" v-model="subscriber.last_name">
            </div>
            <div class="form-group">
                <label>Email address</label>
                <input type="email" class="form-control" placeholder="Email" v-model="subscriber.email">
            </div>
            <div class="form-group">
                <label class="control-label">Time Zone:</label>
                <select v-model="subscriber.time_zone" class="form-control">
                    <option :value="offset" v-for="(timeZone, offset) in timeZones" :selected="offset == subscriber.time_zone">
                        {{ timeZone }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>EnneaThought Subscriptions Types:</label><br/>
                <label class="checkbox-inline" v-for="(personality, key) in personalities">
                    <input type="checkbox" v-model="personality_id" :value="key"> {{ personality }}
                </label>
            </div>
            <br/>
            <div class="row">
                <div class="col-xs-6">
                    <button class="btn btn-primary">Submit</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    import Alert from './Alert.vue';
    import httpEnneathought from '../../http/Enneathought';
    export default{
        data() {
            return {
                personalities: [],
                personality_id: [],
                showAlert: false,
                status: null,
                message: null,
                timeZones: []
            }
        },
        props: ['subscriber'],
        methods: {
            updateSubscriber(event) {
                event.preventDefault();
                httpEnneathought.updateSubscriber(this.subscriber.obfuscated_id, {
                    first_name: this.subscriber.first_name,
                    last_name: this.subscriber.last_name,
                    email: this.subscriber.email,
                    personality_id: this.personality_id,
                    time_zone: this.subscriber.time_zone
                }).then(({data}) => {
                    this.status = 'success';
                    this.message = 'Subscriber Update Successful';
                    this.showAlert = true;
                    this.$emit('subscriber-updated', data);
                }).catch(({response}) => {
                    this.status = 'danger';
                    this.message = this.buildAlertErrorMessage(response.data);
                    this.showAlert = true;
                });
            },
            fetchPersonalities() {
                httpEnneathought.getPersonalities().then(({data}) => {
                    this.personalities = data;
                });
            },
            fetchTimeZones() {
                httpEnneathought.getTimezones().then(({data}) => {
                    this.timeZones = data;
                });
            },
            buildAlertErrorMessage(errors) {
                const fields = Object.keys(errors);
                let message = "";
                fields.forEach((item) => {
                    errors[item].forEach((error) => {
                        message += error+' ';
                    });
                });
                return message;
            }
        },
        mounted() {
            this.fetchPersonalities();
            this.fetchTimeZones();
            this.personality_id = this.subscriber.subscriptions.map((subscription) => { return subscription.subject_id});
        },
        components: {
            alert: Alert
        }
    }

</script>
