export default class Refund {

    static getOrder(orderId) {
        return window.axios.get('/administration/api/refund/'+orderId);
    }

    static getRefund(orderId, testCodes) {
        return window.axios.post('/administration/api/refund/'+orderId+'/calculate', {
            test_codes: testCodes
        });
    }

    static createPartialRefund(orderId, testCodes) {
        return window.axios.post('/administration/api/refund/'+orderId+'/create', {
            type: 'partial',
            test_codes: testCodes
        });
    }

    static createFullRefund(orderId) {
        return window.axios.post('/administration/api/refund/'+orderId+'/create', {
            type: 'full'
        });
    }

}