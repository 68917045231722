export default class Coupon {
    static sendCreateCouponCode(couponCode) {
        return window.axios.post('/administration/discount/', {
            'name': couponCode.name,
            'code': couponCode.code,
            'amount': couponCode.amount,
            'limit': couponCode.limit,
            'dateRange': couponCode.dateRange,
            'restrictEmail': couponCode.restrictEmail,
            'restrictedEmails': couponCode.restrictedEmails
        });
    }
    static sendUpdateCouponCode(couponCode, discountId) {
        return window.axios.put('/administration/discount/'+discountId, {
            'name': couponCode.name,
            'code': couponCode.code,
            'amount': couponCode.amount,
            'limit': couponCode.limit,
            'dateRange': couponCode.dateRange,
            'restrictEmail': couponCode.restrictEmail,
            'restrictedEmails': couponCode.restrictedEmails
        });
    }
    static fetchCouponCode(discountId) {
        return window.axios.get('/administration/discount/'+discountId);
    }
    static formatFromServer(data) {
        return {
            'name': data['discount'].name,
            'code': data['discount'].code,
            'amount': data['discount'].amount,
            'limit': data['discount'].limit,
            'dateRange': [data['discount'].start_date, data['discount'].end_date],
            'restrictEmail': data['approvedEmails'] !== "",
            'restrictedEmails': data['approvedEmails']
        };
    }
}