<template>
    <div class="input-group">
        <input class="form-control" @input="handleInput" v-bind:class="{ base : !changed }" size="small"
               v-model.number="count" type="number" :max="max_count" :min="1" :aria-label="aria_label"/>
        <div class="input-group-btn" v-if="changed">
            <button class="btn btn-primary" @click="submit()">Update</button>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import {mapState} from 'vuex';

    export default {
        data() {
            return {
                count: this.order_count
            }
        },
        props: [
            "id",
            "cost",
            "order_count",
            "guest",
            "aria_label"
        ],
        computed: {
            changed: function () {
                return this.count !== this.order_count;
            },
            max_count: function () {
                return this.guest ? 20 - this.total_count + this.order_count : 1000;
            },
            ...mapState("cart", [
                'total_count'
            ])
        },
        methods: {
            handleInput(event) {
                if (this.guest) {
                    const value = Number(event.target.value);
                    if (value) {
                        if (value > this.max_count) {
                            $("#test_count_alert").show();

                            $("#test_count_alert_close_button").click(function () {
                                $('#test_count_alert').hide();
                            });
                            this.count = this.max_count
                        }
                        else {
                            this.count = value;
                        }
                    }
                }
            },
            submit() {
                this.$store.dispatch('cart/updateTestInCart', {
                    test_id: this.id,
                    count: this.count
                }).then(() => {
                }).catch(({response}) => {
                    console.log("error: " + response);
                });
            },
        }
    }
</script>
<style>
    .base {
        border-radius: 4px !important;
    }
</style>
