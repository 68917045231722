import { createStore } from "vuex";

import cart from "./modules/cart";
import test from "./modules/test";

export default createStore({
  modules: {
    cart,
    test,
  },
});
