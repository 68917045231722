<template>
  <div>
    <el-row :gutter="40">
      <el-col :md="{span: 9, offset: 3}" :sm="13" :xs="24">
        <p class="question">{{ activeQuestionIndex + 1 }}. {{ questions[activeQuestionIndex].question }}</p>
        <ol type="A">
          <li v-for="answer in questionAnswers"
              :class="{mostLikeText: formData.most_like_id === answer.id, mostUnlikeText: formData.most_unlike_id === answer.id}">
            {{ answer.answer }}
          </li>
        </ol>
      </el-col>
      <el-col :md="{span: 8, offset: 3}" :sm="11" :xs="24">
        <p class="mostLikeText">{{ localText.mostLike }}</p>
        <el-radio-group v-model="formData.most_like_id" @change="questionAnswered('most')">
          <el-radio-button v-for="(answer, index) in questionAnswers" :label="answer.id">{{ answer.letter }}
          </el-radio-button>
        </el-radio-group>
        <p class="mostUnlikeText">{{ localText.leastLike }}</p>
        <el-radio-group v-model="formData.most_unlike_id" fill="#FF4949" @change="questionAnswered('least')">
          <el-radio-button v-for="(answer, index) in questionAnswers" :label="answer.id">{{ answer.letter }}
          </el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
  </div>
</template>
<style>
.mostLikeText {
  color: #20A0FF;
  font-weight: bold;
}

.mostUnlikeText {
  color: #FF4949;
  font-weight: bold;
}

.question {
  font-weight: bold;
}
</style>
<script>
import {mapActions, mapMutations, mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      formData: {
        most_like_id: null,
        most_unlike_id: null
      },
      questionAnswers: [],
    }
  },
  props: [
    'localText'
  ],
  computed: {
    ...mapState('test', ['questions', 'answers', 'activeQuestionIndex',]),
  },
  methods: {
    questionAnswered(changed) {
      if (this.formData.most_like_id === this.formData.most_unlike_id) {
        if (changed === 'most') {
          this.formData.most_unlike_id = null;
        } else {
          this.formData.most_like_id = null;
        }
      }
      this.emitAnswer();

    },
    emitAnswer() {
      this.$emit('questionAnswered', {
        question_id: this.questions[this.activeQuestionIndex].id,
        most_like_id: this.formData.most_like_id,
        most_unlike_id: this.formData.most_unlike_id
      });
    },
    clearForm() {
      this.formData.most_like_id = null;
      this.formData.most_unlike_id = null;
    },
    setup() {
      this.questionAnswers = this.questions[this.activeQuestionIndex].answers.map((answer, index) => {
        answer.letter = String.fromCharCode('A'.charCodeAt(0) + index);
        return answer;
      });

      if (this.answers[this.activeQuestionIndex]) {
        this.formData.most_like_id = this.answers[this.activeQuestionIndex].most_like_id ? this.answers[this.activeQuestionIndex].most_like_id : null;
        this.formData.most_unlike_id = this.answers[this.activeQuestionIndex].most_unlike_id ? this.answers[this.activeQuestionIndex].most_unlike_id : null;
        this.emitAnswer();
      }
    }
  },
  watch: {
    activeQuestionIndex() {
      this.clearForm();
      this.setup();
    }
  },
  created() {
    this.setup();
  }
}
</script>