<template>
  <div>
    <div v-if="loading" style="text-align: center">
      <loading-indicator />
    </div>
    <div class="container-fluid" v-else>
      <form>
        <div class="form-group col-sm-6">
          <label for="name">Name</label>
          <input
            type="text"
            id="name"
            class="form-control"
            v-model="coupon_code.name"
          />
        </div>
        <div class="form-group col-sm-6">
          <label for="code">Code</label>
          <input
            type="text"
            id="code"
            class="form-control"
            v-model="coupon_code.code"
          />
        </div>
        <div class="form-group col-sm-6">
          <label for="amount">Amount (%)</label>
          <input
            type="number"
            id="amount"
            class="form-control"
            v-model="coupon_code.amount"
          />
        </div>
        <div class="form-group col-sm-6">
          <label for="limit">Limit</label>
          <input
            type="number"
            id="limit"
            class="form-control"
            v-model="coupon_code.limit"
          />
        </div>
        <div class="form-group col-sm-12">
          <label class="control-label">Valid Date Range</label><br />
          <el-date-picker
            v-model="coupon_code.dateRange"
            type="daterange"
            placeholder="Pick a range"
            format="MM/DD/YY"
          />
        </div>
        <div class="form-group col-sm-12">
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="coupon_code.restrictEmail" />
              Restrict Code by Email?
            </label>
          </div>
        </div>
        <div class="form-group col-xs-12" v-if="coupon_code.restrictEmail">
          <label
            >Enter a comma separated list of the emails you would like to be
            allowed to use this code.</label
          >
          <textarea
            v-model="coupon_code.restrictedEmails"
            cols="100"
            rows="10"
          ></textarea>
        </div>
        <div class="form-group col-xs-12">
          <button
            class="btn btn-primary"
            @click.prevent="updateCouponCode()"
            v-if="discountId"
          >
            Update Coupon Code
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="createCouponCode()"
            v-else
          >
            Create Coupon Code
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import httpCoupon from "../http/Coupon";
import { ElDatePicker } from "element-plus";
import "@element-plus/theme-chalk/dist/index.css";
// https://github.com/element-plus/element-plus/discussions/5658
// Element Plus uses English as the default language. To change the default language, please refer to the Global Configuration section and set the locale property.
// See app.js as wellIm

export default {
  data() {
    return {
      coupon_code: {
        name: null,
        code: null,
        amount: null,
        limit: null,
        dateRange: null,
        restrictEmail: false,
        restrictedEmails: null,
      },
      loading: false,
    };
  },
  props: ["discountId"],
  components: {
    LoadingIndicator,
    ElDatePicker,
  },
  methods: {
    createCouponCode() {
      this.loading = true;
      httpCoupon
        .sendCreateCouponCode(this.coupon_code)
        .then(({ data }) => {
          this.loading = false;
          window.location.href = "/administration/discount";
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    updateCouponCode() {
      this.loading = true;
      httpCoupon
        .sendUpdateCouponCode(this.coupon_code, this.discountId)
        .then(({ data }) => {
          window.toastr.success(data);
          this.getCouponCode();
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    getCouponCode() {
      this.loading = true;
      httpCoupon
        .fetchCouponCode(this.discountId)
        .then(({ data }) => {
            this.coupon_code = httpCoupon.formatFromServer(data);
            //   Offset dates for UTC to EST conversion
            this.coupon_code.dateRange = this.coupon_code.dateRange.map(
                (date) => {
              return new Date(date).setHours(new Date(date).getHours() + 5);
            }
            );
          this.loading = false;
        })
        .catch(() => {
          window.toastr.error("Error fetching data");
          this.loading = false;
        });
    },
    createMessageArrayFromValidationError(errorObj) {
      let messages = [];
      Object.keys(errorObj).forEach((key) => {
        errorObj[key].forEach((error) => {
          messages.push(error);
        });
      });
      return messages;
    },
  },
  created: function () {
    if (this.discountId != null) {
      this.getCouponCode();
    }
  },
};
</script>
