<template>
  <div>
    <div v-if="loading" style="text-align: center">
      <loading-indicator />
    </div>
    <div class="container-fluid" v-else>
      <form class="form-horizontal">
        <div class="form-group">
          <div class="col-sm-2">
            <label for="name">Name</label>
          </div>
          <div class="col-sm-6">
            <input
              type="text"
              id="name"
              class="form-control"
              v-model="event.name"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-2">
            <label for="contract_number">Contract Number</label>
          </div>
          <div class="col-xs-6">
            <input
              type="text"
              id="contract_number"
              class="form-control"
              v-model="event.contract_number"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-2">
            <label>Date</label>
          </div>
          <div class="col-xs-6">
            <el-date-picker
              v-model="event.date"
              type="date"
              placeholder="Pick a day"
              :picker-options="pickerOptions"
              format="MM/DD/YY"
            ></el-date-picker>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-6">
            <button
              class="btn btn-primary"
              @click.prevent="updateEvent()"
              v-if="eventId"
            >
              Update Event
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="createEvent()"
              v-else
            >
              Create Event
            </button>
          </div>
        </div>
      </form>
      <div v-if="eventId">
        <h3>Orders</h3>
        <div v-for="order in orders">
          <a :href="'/administration/order/' + order.id"
            >Order {{ order.id }} -
            {{
              order.organization ? order.organization.organization_name : ""
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import httpEvent from "../http/Event";
import { ElDatePicker } from "element-plus";
export default {
  data() {
    return {
      event: {
        name: null,
        contract_number: null,
        date: null,
      },
      loading: false,
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      orders: [],
    };
  },
  props: ["eventId", "isEmbedded"],
  components: {
    LoadingIndicator,
    ElDatePicker,
  },
  methods: {
    createEvent: function () {
      this.loading = true;
      httpEvent
        .sendCreateEvent(this.event)
        .then(({ data }) => {
          this.loading = false;
          if (!this.isEmbedded) {
            window.location.href = "/administration/event";
          } else {
            this.$emit("eventCreated", data);
          }
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    updateEvent: function () {
      this.loading = true;
      httpEvent
        .sendUpdateEvent(this.event, this.eventId)
        .then(({ data }) => {
          window.toastr.success(data);
          this.getEvent();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    getEvent: function () {
      this.loading = true;
      httpEvent
        .getEvent(this.eventId)
        .then(({ data }) => {
          this.event.name = data.name;
          this.event.contract_number = data.contract_number;
          // TODO start using any JS date library
          // Backend is full datetime stored in UTC, front end "translates" for timezone diff
          // Problem being that it's stored as 00:00:00 and so causes it to always
          // Store as previous date- 5 accounts for UTC to EST diff plus an hour for error
          // Should be fine as long as admin is only accessed in EST
          let eventDate = new Date(data.date).setHours(
            new Date(data.date).getHours() + 5
          );
          this.event.date = eventDate;
          this.orders = data.orders;
          this.loading = false;
        })
        .catch(({ response }) => {
          window.toastr.error("Error fetching event.");
          this.loading = false;
        });
    },
    createMessageArrayFromValidationError: function (errorObj) {
      let messages = [];
      Object.keys(errorObj).forEach((key) => {
        errorObj[key].forEach((error) => {
          messages.push(error);
        });
      });
      return messages;
    },
    disabledDate: function (date) {
      let now = new Date();
      now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return Date.parse(date) < now;
    },
  },
  created() {
    if (this.eventId) {
      this.getEvent();
    }
  },
};
</script>
