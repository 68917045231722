<template>
    <el-row>
        <el-col :md="{span:12, offset:6}" :sm="24">
            <el-form :model="formData" label-position="top" labelWidth="200px">
                <el-form-item :label="localText.typeQuestion">
                    <el-select v-model="formData.personality" :placeholder="localText.typePlaceholder">
                        <el-option v-for="type in personalityTypeOptions" :label="type.name" :value="type.id"></el-option>
                        <el-option :label="localText.unknown" :value="''"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-button :loading="submitting" type="primary" @click="finishTest" style="float: right">
                {{ localText.finish }}
            </el-button>
        </el-col>
    </el-row>
</template>
<style>
    .el-select {
        width: 100%;
    }
    .el-select-dropdown__wrap {
        max-height: 400px;
    }
</style>
<script>
    export default {
        data() {
            return {
                formData: {
                    personality: null
                },
                personalityTypeOptions: [],
            }
        },
        props: [ 'localText', 'submitting' ],
        methods: {
            finishTest: function() {
                this.$emit('testFinished', this.formData);
            },
            getPersonalityTypes: function() {
                window.axios.get('/api/options/personalities').then(({data}) => {
                    this.personalityTypeOptions = data;
                });
            },
        },
        mounted() {
            this.getPersonalityTypes();
        }
    }
</script>