<template>
    <div>
        <ul>
            <li><strong>Type:</strong> {{ baseClass(notification.type) }}</li>
            <li><strong>Sent At:</strong> {{ notification.created_at }}</li>
            <li><strong>Read At:</strong> {{ notification.read_at === null ? '--' : notification.read_at}}</li>
            <li>
                <strong>Subject:</strong>
                <ul>
                    <li v-for="data in notification.data">
                        Day: {{ data.day }} -- Personality: {{ data.personality_id }}
                        <code>{{ data.content }}</code>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
    export default{
        props: ['notification'],
        methods: {
            baseClass(str) {
                let parsed = str.split('\\');
                return parsed[parsed.length -1];
            },
        }
    }
</script>
