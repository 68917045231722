<template>
  <div>
    <VMarkdownEditor
      v-model="content"
      ref="markdownEditor"
      class="editor-class"
    />

    <el-alert
      v-if="renderContentError"
      title="Content is required"
      type="error"
      style="margin-bottom: 12px"
    >
    </el-alert>

    <el-button type="primary" @click.prevent="save()">Save</el-button>
    <el-button type="secondary" @click.prevent="clear()">Clear</el-button>
  </div>
</template>

<script>
import { VMarkdownEditor } from "vue3-markdown";
import "vue3-markdown/dist/style.css";

export default {
  data() {
    return {
      content: "",
      renderContentError: false,
    };
  },
  props: {
    notificationType: {
      type: String,
    },
    typesAndContents: {
      type: Array,
    },
  },
  components: {
    VMarkdownEditor,
  },
  created() {
    this.loadContent(this.notificationType, this.typesAndContents);
  },

  watch: {
    notificationType(val, oldVal) {
      if (val !== oldVal) {
        this.loadContent(this.notificationType, this.typesAndContents);
      }
    },
  },

  methods: {
    loadContent(notificationType, typesAndContents) {
      typesAndContents.forEach((pair) => {
        if (notificationType == pair["type"]) {
          this.content = pair["content"];
        }
      });
    },
    save() {
      if (this.content === "") {
        this.renderContentError = true;
      }
      this.$emit("save-partial-notification", {
        notificationType: this.notificationType,
        content: this.content,
      });
    },
    clear() {
      this.content = "";
    },
  },
};
</script>
<style scoped>
.editor-class {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 500px;
}
</style>
