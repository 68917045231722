<template>
  <div>
    <div v-if="loading" style="text-align: center">
      <loading-indicator />
    </div>
    <div v-else>
      <el-table
        ref="payoutTable"
        :data="tableData"
        show-summary
        :summary-method="summary"
        border
        highlight-current-row
        @current-change="handleCurrentChange"
        :row-class-name="checkReconciled"
        maxHeight="700"
      >
        <el-table-column prop="arrival_date" label="Payout Date">
        </el-table-column>
        <el-table-column
          prop="amount"
          label="Amount"
          align="right"
          :formatter="format"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import { ElTable } from "element-plus";

export default {
  props: ["loading", "tableData", "format"],
  components: {
    LoadingIndicator,
    ElTable,
  },
  methods: {
    summary(params) {
      const { cols, data } = params;
      const sums = [];
      let count = 0.0;

      sums[0] = "Total";
      if (data) {
        data.forEach(function (item) {
          count += item.amount;
        });
      }
      sums[1] = "$" + count.toFixed(2);
      return sums;
    },
    handleCurrentChange(val) {
      this.$emit("selectedChanged", val.id);
    },
    checkReconciled(row, index) {
      if (row.reconciled_at !== null) {
        return "positive-row";
      }
      return "";
    },
  },
};
</script>
<style>
.el-table .positive-row {
  background: #e2f0e4;
}
</style>
