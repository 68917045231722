export default class Notification {
    static sendTestEmail(notification) {
        return window.axios.post('/administration/notification/test', {
            'ind': notification.ind ? notification.ind : false,
            'org': notification.org ? notification.org : false,
            'subject': notification.subject,
            'body': notification.body
        });
    }

    static sendEmail(notification) {
        return window.axios.post('/administration/notification/', {
            'ind': notification.ind ? notification.ind : null,
            'org': notification.org ? notification.org : null,
            'subject': notification.subject,
            'body': notification.body
        });
    }

    static calculateRecipients(ind, org) {
        return window.axios.post('/administration/notification/recipients', {
            'ind': ind,
            'org': org
        });
    }
}