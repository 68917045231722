<template>
  <form>
    <div class="row align-items-center mb-3">
      <label class="col-auto strong has-medium-font-size"
        >Select Quantity:</label
      >
      <div class="col-auto">
        <div class="input-group input-adder">
          <el-input-number
            v-if="guest"
            size="small"
            v-model="formData.order_count"
            controls-position="right"
            class="form-control"
            :min="1"
            :max="max_count"
            :label="
              this.test_type === 'RHETI'
                ? 'RHETI Test Quantity'
                : 'IVQ Test Quantity'
            "
          ></el-input-number>
          <el-input-number
            v-else
            size="small"
            v-model="formData.order_count"
            controls-position="right"
            class="form-control"
            :min="1"
            :label="
              this.test_type === 'RHETI'
                ? 'RHETI Test Quantity'
                : 'IVQ Test Quantity'
            "
          ></el-input-number>
          <button
            :id="this.test_type"
            class="btn btn-primary add-to-cart"
            data-toggle="modal"
            :disabled="formData.language === null"
            @click.prevent="submit()"
            :aria-label="
              this.test_type === 'RHETI'
                ? 'Add RHETI Test to cart'
                : 'Add IVQ Test to cart'
            "
          >
            <!-- Added d-none so this defaults to English until multiple languages supported -->
            <div class="d-none">
              <label>Language :</label>
              <select
                :aria-label="
                  this.test_type === 'RHETI'
                    ? 'RHETI Test Language'
                    : 'IVQ Test Language'
                "
                class="form-control"
                name="language"
                v-model="formData.language"
              >
                <option
                  v-if="test_options.length !== 1"
                  value="null"
                  selected="true"
                  disabled="disabled"
                  :label="
                    this.test_type === 'RHETI'
                      ? 'RHETI Test Language'
                      : 'IVQ Test Language'
                  "
                >
                  Select
                </option>
                <option v-for="(test_option, key) in test_options" :value="key">
                  {{ test_option.language }}
                </option>
              </select>
            </div>
            <span class="glyphicon glyphicon-shopping-cart"></span> Add to Cart
          </button>
        </div>
      </div>
    </div>
    <!-- start modal -->

    <div
      class="modal fade"
      :id="'addCartModal-' + this.test_type"
      tabindex="-1"
      aria-labelledby="$target-parent"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="addCartModalLabel">
              Added {{ added }} {{ added === 1 ? "Test" : "Tests" }} to cart
            </h2>
            <a
              type="button"
              data-bs-dismiss="modal"
              class="btn-close"
              aria-label="Close"
            ></a>
          </div>
          <div class="modal-body">
            <div class="text-end mb-4">
              <a href="/orders" class="view-cart-link"
                >View Cart ({{ this.total_count }})</a
              >
            </div>

            <div v-if="test === 'Combo'" class="cart-items">
              <div v-for="test in this.test_counts" :key="test.id">
                <div class="row mb-4">
                  <div class="col-md-7">
                    <span class="h4">{{ test.test.short_name }}</span> &#215;{{
                      test.count
                    }}
                    test codes
                  </div>
                  <div class="col-md-4 offset-md-1 text-end">
                    <template v-if="test.discount.length !== 0">
                      <span class="discount d-block red"
                        >Discount {{ test.discount.discount.amount }}%: -${{
                          test.discount.discountAmount.toFixed(2)
                        }}</span
                      >
                      <span class="subtotal"
                        >Subtotal: ${{
                          test.discount.totalWithDiscount.toFixed(2)
                        }}</span
                      >
                    </template>
                    <template v-else>
                      <span class="subtotal"
                        >Subtotal: ${{
                          (test.count * test.test.cost).toFixed(2)
                        }}</span
                      >
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="test" class="cart-items">
              <div>
                <div class="row mb-4">
                  <div class="col-md-7">
                    <span class="h4">{{ test.test.short_name }}</span> &#215;{{
                      test.count
                    }}
                    test codes
                  </div>
                  <div class="col-md-4 offset-md-1 text-end">
                    <template v-if="test.discount.length !== 0">
                      <span class="discount d-block red"
                        >Discount {{ test.discount.discount.amount }}%: -${{
                          test.discount.discountAmount.toFixed(2)
                        }}</span
                      >
                      <span class="subtotal"
                        >Subtotal: ${{
                          test.discount.totalWithDiscount.toFixed(2)
                        }}</span
                      >
                    </template>
                    <template v-else>
                      <span class="subtotal"
                        >Subtotal: ${{
                          (test.count * test.test.cost).toFixed(2)
                        }}</span
                      >
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-7">
                <p>
                  Each test code is valid for one-time use and expires one year
                  from date of purchase.
                </p>
              </div>
              <div class="col-md-4 offset-md-1 text-end">
                <p
                  class="strong has-medium-font-size"
                  v-if="formData.language !== null"
                >
                  <!--{{test_options[formData.language].language}}-->
                  <!-- {{ test_type }} -->
                  Total ({{ total_count }}): ${{ total_amount.toFixed(2) }}
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer text-end">
            <a tabindex="0" @click="hideModal" style="cursor: pointer"
              >Continue Shopping</a
            >
            <a class="btn btn-primary ms-4" href="/payment/checkout"
              >Proceed to Checkout</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
  </form>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
    return {
      formData: {
        language: this.test_options.length === 1 ? 0 : null,
        order_count: 1,
      },
      added: 0,
      showModal: false,
    };
  },
  computed: {
    expireDate: function () {
      return moment().add(1, "years").format("YYYY-MM-DD");
    },
    max_count: function () {
      const max_tests = 20;
      const tests_remaining = max_tests - this.total_count;
      if (this.test_type === "Combo") {
        return Math.floor(tests_remaining / 2);
      } else {
        return tests_remaining;
      }
    },
    test: function () {
      if (this.formData.language === null) {
        return null;
      }
      if (this.test_type === "Combo") {
        return this.test_type;
      }
      return this.test_counts.find((test) => {
        return test.test.id === this.test_options[this.formData.language].id;
      });
    },
    ...mapState("cart", ["total_count", "test_counts", "total_amount"]),
  },
  props: {
    test_type: {
      type: String,
      required: true,
      validator(value, props) {
        return ["RHETI", "IVQ", "Combo"].includes(value);
      },
    },
    test_options: Array,
    guest: null,
  },
  methods: {
    async submit() {
      const order_total_count =
        this.test_type === "Combo"
          ? this.formData.order_count * 2
          : this.formData.order_count;

      if (this.guest && this.total_count + order_total_count > 20) {
        $("#test_count_alert").show();
        $("#test_count_alert_close_button").click(function () {
          $("#test_count_alert").hide();
        });
        return;
      } else {
        // TODO add combo type
        $("#addCartModal-" + this.test_type)
          .modal("show")
          .addClass("in");
      }

      this.added = order_total_count;
      if (this.guest && order_total_count + this.total_count > 20) {
        this.added = 20 - this.total_count;
      }

      if (this.test_type === "Combo") {
        const test_types_and_ids = [
          { test_type: "RHETI", id: 1 },
          { test_type: "IVQ", id: 2 },
        ];

        for (let index = 0; index < test_types_and_ids.length; index++) {
          const test_type_and_id = test_types_and_ids[index];
          this.$store.dispatch("cart/order", {
            id: test_type_and_id["id"],
            count: this.formData.order_count,
            guest: this.guest,
          });

          try {
            await this.$store
              .dispatch("cart/updateCart", {
                test_type: test_type_and_id["test_type"],
                order_count: this.formData.order_count,
                test_id: test_type_and_id["id"],
              })
              .catch(({ response }) => {
                console.log("error: " + response);
              });
          } catch (error) {
            console.log("error:", error.response);
          }
        }
      } else {
        const test_id = this.test_options[this.formData.language].id;
        this.$store.dispatch("cart/order", {
          id: test_id,
          count: this.formData.order_count,
          guest: this.guest,
        });

        this.$store
          .dispatch("cart/updateCart", {
            test_type: this.test_type,
            order_count: this.formData.order_count,
            test_id: test_id,
          })
          .catch(({ response }) => {
            console.log("error: " + response);
          });
      }
    },
    hideModal() {
      $("#addCartModal-" + this.test_type)
        .modal("hide")
        .addClass("out");
    },
  },
};
</script>
