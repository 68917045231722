<template>
    <div>
        <alert v-if="showAlert" :message="message" :status="status" @dismiss="showAlert = false;"/>
        <form @submit="savePromotion($event)">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Text</label>
                        <textarea rows="6" cols="60" v-model="promotion.text" class="form-control"></textarea>
                        <span class="help-block">
                            To bold text, surround the text as follows: &lt;b&gt;<b>Bolded Text</b>&lt;/b&gt;<br>
                            To italicize text, surround the text as follows: &lt;em&gt;<em>Italicized Text</em>&lt;/em&gt;<br>
                            To underline text, surround the text as follows: &lt;u&gt;<u>Underlined Text</u>&lt;/u&gt;
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Image</label>
                        <input type="file" @change="onImageChange($event)">
                        <span class="help-block">
                            Acceptable image types are jpeg, png, bmp, gif, or svg.  Images should be at least 250
                            pixels wide to avoid distortion.
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Link</label>
                        <input type="url" class="form-control" placeholder="Link" v-model="promotion.link" required>
                        <span class="help-block">Please include http:// or https:// in your link text</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Start Date</label>
                        <input id="start_date" type="text" class="form-control" v-model="promotion.start_date" required>
                        <span class="help-block">Format: yyyy-mm-dd (example: 2017-01-01)</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>End Date</label>
                        <input id="end_date" type="text" class="form-control" v-model="promotion.end_date" required>
                        <span class="help-block">Format: yyyy-mm-dd</span>
                    </div>
                </div>
            </div>
            <br/><button class="btn btn-primary" type="submit">Submit</button>
            <button v-if="promotionId" class="btn btn-success" @click.prevent="sendTestMessage">Send Test Message</button>
            <br/><br/>
        </form>
    </div>
</template>
<style scoped>
    img {
        max-width: 200px
    }
</style>
<script>
    import Alert from './Alert.vue';
    import httpEnneathought from '../../http/Enneathought';
    //import Datepicker from 'vuejs-datepicker';

    export default{
        data() {
            return {
                showAlert: false,
                status: null,
                message: null,
                promotion: {
                    text: '',
                    image: null,
                    link: '',
                    start_date: null,
                    end_date: null
                }
            }
        },
        props: {
            promotionId: {
                type: Number,
                required: false,
                default: null
            }
        },
        methods: {
            savePromotion(event) {
                event.preventDefault();
                let data = {};
                if(this.promotionId) {
                    data.id = this.promotionId;
                }
                data.text = this.promotion.text;
                data.link = this.promotion.link;
                data.start_date = this.promotion.start_date;
                data.end_date = this.promotion.end_date;
                if(this.promotion.image !== null && typeof this.promotion.image !== 'string') {
                    data.image = this.promotion.image;
                }
                if(this.promotionId !== null) {
                    httpEnneathought.updatePromotion(this.promotionId, data)
                        .then(({data}) => { this.afterSave(data); })
                        .catch(({response}) => { this.saveError(response); });
                } else {
                    httpEnneathought.createPromotion(data)
                        .then(({data}) => { this.afterSave(data); })
                        .catch(({response}) => { this.saveError(response); });
                }
            },
            afterSave(data) {
                this.status = 'success';
                this.message = 'Promotion Update Successful';
                this.showAlert = true;
                this.promotion = data;
                this.$emit('promotion-updated', data);
            },
            saveError(response) {
                console.log('Error: ', response);
                this.status = 'danger';
                this.message = this.buildAlertErrorMessage(response.data);
                this.showAlert = true;
            },
            buildAlertErrorMessage(errors) {
                const fields = Object.keys(errors);
                let message = "";
                fields.forEach((item) => {
                    errors[item].forEach((error) => {
                        message += error+' ';
                    });
                });
                return message;
            },
            onImageChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.promotion.image = files[0]
            },
            sendTestMessage() {
                httpEnneathought.sendPromotionTestMessage(this.promotionId).then(({data}) => {
                    this.status = 'success';
                    this.message = data.message;
                    this.showAlert = true;
                });
            },
            fetchPromotion() {
                httpEnneathought.getPromotion(this.promotionId).then(({data}) => {
                    this.promotion.text = data.text;
                    this.promotion.link = data.link;
                    this.promotion.start_date = data.start_date;
                    this.promotion.end_date = data.end_date;
                });
            }
        },
        mounted() {
            if(this.promotionId !== null) {
                this.fetchPromotion();
            }
        },
        components: {
            alert: Alert
        }
    }
</script>
