<template>
  <div>
    <div v-if="loading" style="text-align: center">
      <loading-indicator />
    </div>
    <div v-else>
      <br />
      <div
        class="alert alert-warning alert-dismissable"
        v-if="summary.unreconciled.length > 0"
      >
        <a href="#" class="close" data-dismiss="alert" aria-label="close"
          >&times;</a
        >
        <strong>Warning!</strong> There
        {{ summary.unreconciled.length === 1 ? "is" : "are" }}
        {{ summary.unreconciled.length }}
        {{ summary.unreconciled.length === 1 ? "result" : "results" }} not
        reconciled for this time period.
      </div>
      <h1>Financial Data</h1>
      <div class="summary-box">
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Gross Sales Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.grossSalesTotal) }}
            </div>
          </el-col>
        </el-row>
        <div v-if="summary.discounts < 0">
          <el-row
            class="summary-row"
            v-for="discount in summary.discounts"
            :key="discount"
          >
            <el-col class="summary-label" :span="16">
              <div style="text-align: center">
                {{ discount.name }} - Discount Amount
              </div>
            </el-col>
            <el-col class="summary-value" :span="5">
              <div style="text-align: right">
                {{ formatCurrency(discount.discountTotal) }}
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Stripe Fees Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.feesTotal) }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Dispute Fees Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.disputeFeesTotal) }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Refunded Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.refundsTotal) }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Disputed Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.disputesTotal) }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Taxes Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.taxTotal) }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Net Amount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ formatCurrency(summary.netTotal) }}
            </div>
          </el-col>
        </el-row>
      </div>
      <h1>Test Code Counts</h1>
      <div class="summary-box">
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Test Codes Sold</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ summary.grossTestCodesCount }}
            </div>
          </el-col>
        </el-row>
        <div v-if="summary.netTestCodesCount > 0">
          <el-row
            class="summary-row"
            v-for="code in summary.discounts"
            :key="code"
          >
            <el-col class="summary-label" :span="16">
              <div style="text-align: center">{{ code.name }} - Discount</div>
            </el-col>
            <el-col class="summary-value" :span="5">
              <div style="text-align: right">{{ code.testCodesCount }}</div>
            </el-col>
          </el-row>
        </div>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Refunded Test Codes</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ summary.refundedTestCodesCount }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Disputed Test Codes</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ summary.disputedTestCodesCount }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary-row">
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">Net Test Codes</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">{{ summary.netTestCodesCount }}</div>
          </el-col>
        </el-row>
      </div>
      <h1>Event Test Code Counts</h1>
      <div class="summary-box">
        <el-row
          class="summary-row"
          v-for="event in summary.eventData"
          :key="event"
        >
          <el-col class="summary-label" :span="16">
            <div style="text-align: center">{{ event.event.name }}</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">{{ event.count }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import { ElRow, ElCol } from "element-plus";

export default {
  props: ["summary", "loading"],
  components: {
    LoadingIndicator,
    ElRow,
    ElCol,
  },
  methods: {
    formatCurrency(data) {
      return data < 0
        ? "$(" + Math.abs(data).toFixed(2) + ")"
        : "$" + data.toFixed(2);
    },
  },
};
</script>
<style>
.summary-box {
  border-bottom: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
}

.summary-row {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-top: 1px solid #dfe6ec;
  font-size: 14px;
}

.summary-label {
  font-weight: bold;
  background-color: #eef1f6;
  padding-top: 8px;
  padding-bottom: 8px;
}

.summary-value {
  padding-top: 8px;
}
</style>
