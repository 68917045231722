<template>
  <div class="summary-div">
    <div class="summary-box">
      <el-row class="summary-row">
        <el-col class="summary-value" :span="9"></el-col>
        <el-col class="summary-value" :span="5"
          ><div style="text-align: center">TOTAL</div></el-col
        >
        <el-col class="summary-value" :span="5"
          ><div style="text-align: center">RHETI-EN</div></el-col
        >
        <el-col class="summary-value" :span="5"
          ><div style="text-align: center">IVQ-EN</div></el-col
        >
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Gross Test Code Count</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{
              details.grossTestCodesCount +
              details.freeTestCodesCount +
              details.eventTestCodesCount
            }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{
              details.tests.RHETI_EN.grossTestCodesCount +
              details.tests.RHETI_EN.freeTestCodesCount +
              details.tests.RHETI_EN.eventTestCodesCount
            }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{
              details.tests.IVQ_EN.grossTestCodesCount +
              details.tests.IVQ_EN.freeTestCodesCount +
              details.tests.IVQ_EN.eventTestCodesCount
            }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Refunded Test Code Count</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ details.refundedTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ details.tests.RHETI_EN.refundedTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ details.tests.IVQ_EN.refundedTestCodesCount }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Disputed Test Code Count</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ details.disputedTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ details.tests.RHETI_EN.disputedTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ details.tests.IVQ_EN.disputedTestCodesCount }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Free Test Code Count</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ details.freeTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ details.tests.RHETI_EN.freeTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ details.tests.IVQ_EN.freeTestCodesCount }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Prepaid/Contract Test Code Count</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ details.eventTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ details.tests.RHETI_EN.eventTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ details.tests.IVQ_EN.eventTestCodesCount }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Net Test Code Count</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ details.netTestCodesCount + details.eventTestCodesCount }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{
              details.tests.RHETI_EN.netTestCodesCount +
              details.tests.RHETI_EN.eventTestCodesCount
            }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{
              details.tests.IVQ_EN.netTestCodesCount +
              details.tests.IVQ_EN.eventTestCodesCount
            }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Gross Test Sales</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.grossSalesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.RHETI_EN.grossSalesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.IVQ_EN.grossSalesTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row
        class="summary-row"
        v-for="(discount, index) in details.discounts"
        :key="discount"
      >
        <template v-if="discount.testCodesCount > 0">
          <el-col class="summary-label" :span="9">
            <div style="text-align: center">{{ discount.name }} Discount</div>
          </el-col>
          <el-col class="summary-value" :span="5">
            <div style="text-align: right">
              {{ discount.testCodesCount }} -
              {{ formatAsCurrency(discount.discountTotal) }}
            </div>
          </el-col>
          <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
            <div style="text-align: right">
              {{ details.tests.RHETI_EN.discounts[index].testCodesCount }}
              -
              {{
                formatAsCurrency(
                  details.tests.RHETI_EN.discounts[index].discountTotal
                )
              }}
            </div>
          </el-col>
          <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
            <div style="text-align: right">
              {{ details.tests.IVQ_EN.discounts[index].testCodesCount }} -
              {{
                formatAsCurrency(
                  details.tests.IVQ_EN.discounts[index].discountTotal
                )
              }}
            </div>
          </el-col>
        </template>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Refunds Amount</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.refundsTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.RHETI_EN.refundsTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.IVQ_EN.refundsTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Stripe Fees</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.feesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.RHETI_EN.feesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.IVQ_EN.feesTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Dispute Fees</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.disputeFeesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.RHETI_EN.disputeFeesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.IVQ_EN.disputeFeesTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Disputes</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.disputesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.RHETI_EN.disputesTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.IVQ_EN.disputesTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Taxes</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.taxTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Net Sales Amount</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.netTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.RHETI_EN.netTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right">
            {{ formatAsCurrency(details.tests.IVQ_EN.netTotal) }}
          </div>
        </el-col>
      </el-row>
      <el-row class="summary-row">
        <el-col class="summary-label" :span="9">
          <div style="text-align: center">Deposited Amount</div>
        </el-col>
        <el-col class="summary-value" :span="5">
          <div style="text-align: right">
            {{ formatAsCurrency(details.netProfitTotal) }}
          </div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.RHETI_EN">
          <div style="text-align: right"></div>
        </el-col>
        <el-col class="summary-value" :span="5" v-if="details.tests.IVQ_EN">
          <div style="text-align: right"></div>
        </el-col>
      </el-row>
    </div>
    <el-row class="summary-button" v-if="details.reconciled_at === null">
      <el-col :span="5" :offset="19">
        <el-button type="success" @click="reconcile">Reconcile</el-button>
      </el-col>
    </el-row>
    <el-row class="summary-button" v-else>
      <el-col :span="16">
        <div>
          Reconciled {{ details.reconciled_at }} UTC by
          {{ details.user.name }}
        </div>
      </el-col>
      <el-col :span="8" class="text-center">
        <el-button type="danger" @click="unreconcile">Un-reconcile</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ElRow, ElCol, ElButton } from "element-plus";

export default {
  components: {
    ElRow,
    ElCol,
    ElButton,
  },
  props: ["details"],
  methods: {
    formatAsCurrency(val) {
      if (val < 0) {
        return "$(" + Math.abs(val).toFixed(2) + ")";
      } else {
        return "$" + val.toFixed(2);
      }
    },
    reconcile() {
      this.$emit("reconcile", this.details.id);
    },
    unreconcile() {
      this.$emit("unreconcile", this.details.id);
    },
  },
};
</script>
<style>
.el-col {
  padding-left: 5px;
  padding-right: 5px;
}
.summary-div {
  margin-bottom: 20px;
}
.summary-box {
  border-bottom: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
}
.summary-row {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-top: 1px solid #dfe6ec;
  font-size: 14px;
}
.summary-label {
  font-weight: bold;
  background-color: #eef1f6;
  padding-top: 8px;
  padding-bottom: 8px;
}
.summary-value {
  padding-top: 8px;
}
.summary-button {
  margin-top: 10px;
}
</style>
