<template>
    <div>
        <modal :show="showPromotionForm" @close="showPromotionForm = false;">
            <div slot="header">
                <p class="h2">Promotion {{ activePromotionId === null ? 'Create' : 'Update' }}</p>
            </div>
            <promotion-form slot="body" :promotionId="activePromotionId" @promotion-updated="handlePromotionUpdate($event)"></promotion-form>
        </modal>
        <modal :show="showPromotionRemoval" @close="showPromotionRemoval = false;">
            <div slot="header">
                <p class="h2">Promotion</p>
            </div>
            <promotion-remove slot="body" :promotionId="activePromotionRemovalId" @promotion-removed="showPromotionRemoval = false;refreshPromotions()" />
        </modal>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">Promotions List</h3>
            </div>
            <div class="panel-body">
                <button class="btn btn-primary btn-md" @click="activePromotionId = null;showPromotionForm = true">
                    Create Promotion
                </button>
                <div class="row">
                    <div class="col-sm-3">
                        <input class="form-control" v-model="search" placeholder="Search"/>
                    </div>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="pointer" @click="setSort('link')">Link</th>
                    <th class="pointer" @click="setSort('text')">Text</th>
                    <th>Image</th>
                    <th class="pointer" @click="setSort('start_date')">Start Date</th>
                    <th class="pointer" @click="setSort('end_date')">End Date</th>
                    <th class="pointer">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="promotion in promotions">
                    <td>
                        {{ promotion.link }}
                    </td>
                    <td>
                        {{ promotion.text }}
                    </td>
                    <td>
                        <img v-if="promotion.image" :src="'/storage/images/' + promotion.image" alt="" height="150" width="150" />
                    </td>
                    <td>
                        {{ promotion.start_date }}
                    </td>
                    <td>
                        {{ promotion.end_date }}
                    </td>
                    <td>
                        <button @click="activePromotionId = promotion.id;showPromotionForm = true" class="btn btn-warning btn-sm">
                            Edit
                        </button>
                        <button @click="activePromotionRemovalId = promotion.id;showPromotionRemoval = true" class="btn btn-danger btn-sm">
                            Delete
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <v-paginator :resource_url="promotionUrl" @update="updatePromotions"></v-paginator>
        </div>
    </div>
</template>
<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
<script>
    import VuePaginator from 'vuejs-paginator';
    import Modal from './Modal.vue';
    import PromotionForm from './PromotionForm.vue';
    import PromotionRemove from './PromotionRemove.vue';
    import httpEnneathought from '../../http/Enneathought';
    export default{
        data(){
            return{
                promotions: [],
                search: '',
                sort: 'created_at',
                sortDir: 'DESC',
                showPromotionForm: false,
                showPromotionRemoval: false,
                activePromotionId: null,
                activePromotionRemovalId: null,
                rand: Math.random()
            }
        },
        components: {
            VPaginator: VuePaginator,
            Modal,
            PromotionForm,
            PromotionRemove
        },
        computed: {
            promotionUrl() {
                return (httpEnneathought.getServiceUrl()+'/promotions?search=' + this.search
                + '&sort=' + this.sort
                + '&sortDir=' + this.sortDir
                + '&rand=' + this.rand);
            }
        },
        methods: {
            updatePromotions(data){
                this.promotions = data;
            },
            setSort(field) {
                if(this.sort === field) {
                    this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
                }else {
                    this.sort = field;
                    this.sortDir = 'DESC';
                }
            },
            handlePromotionUpdate(e){
                this.activePromotionId = e.id;
                this.refreshPromotions();
            },
            refreshPromotions() {
                this.rand = Math.random();
            },
            deletePromotion(promotionId) {
                this.refreshPromotions();
            }
        }
    }
</script>
