<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Subscriber List</h3>
        </div>
        <div class="panel-body">
            <div class="row">
                <div class="col-sm-3">
                    <input class="form-control" v-model="search" placeholder="Search"/>
                </div>
                <div class="col-sm-9">
                    <label class="checkbox-inline" v-for="(name, id) in personalities">
                        <input type="checkbox" v-model="searchPersonalities" :value="id"> {{ name }}
                    </label>
                </div>
            </div>
        </div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="clickable" @click="setSort('last_name')">Name</th>
                <th class="clickable" @click="setSort('email')">Email</th>
                <th class="clickable" @click="setSort('time_zone')">Time Zone</th>
                <th class="clickable" @click="setSort('created_at')">Created On</th>
                <th class="clickable" @click="setSort('unsubscribed_at')">Status</th>
                <th class="clickable">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="subscriber in subscribers">
                <td>{{ subscriber.first_name }} {{ subscriber.last_name }}</td>
                <td>{{ subscriber.email}}</td>
                <td>{{ subscriber.time_zone }}</td>
                <td>{{ subscriber.created_at }}</td>
                <td>{{ subscriber.unsubscribed_at === null ? "Subscribed" : "Unsubscribed: " }} {{ subscriber.unsubscribed_reason }}</td>
                <td>
                    <a class="btn btn-md btn-primary" :href="'/administration/enneathought/subscribers/' + subscriber.obfuscated_id">
                        View
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
        <v-paginator :resource_url="subscriberUrl" @update="updateSubscribers"></v-paginator>
    </div>
</template>
<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
<script>
    import VuePaginator from 'vuejs-paginator';
    import httpEnneathought from '../../http/Enneathought';

    export default{
        data(){
            return{
                subscribers: [],
                loading: false,
                search: '',
                personalities: [],
                searchPersonalities: [],
                sort: 'created_at',
                sortDir: 'ASC'
            }
        },
        components: {
            VPaginator: VuePaginator
        },
        computed: {
            subscriberUrl() {
                return (httpEnneathought.getServiceUrl()+'/subscribers?search=' + this.search
                + '&sort=' + this.sort
                + '&sortDir=' + this.sortDir
                + '&searchPersonalities=' + JSON.stringify(this.searchPersonalities));
            }
        },
        methods: {
            updateSubscribers(data){
                this.subscribers = data;
            },
            fetchPersonalities() {
                httpEnneathought.getPersonalities().then(({data}) => {
                    this.personalities = data;
                    this.searchPersonalities = Object.keys(data);
                });
            },
            setSort(field) {
                if(this.sort === field) {
                    this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
                }else {
                    this.sort = field;
                    this.sortDir = 'ASC';
                }
            }
        },
        mounted() {
            this.fetchPersonalities();
        }
    }
</script>
