const state = {
    total_count: 0,
    test_counts: [],
    total_amount: 0,
    checkoutCart: {},
    errors: {
        address: null,
        payment: null,
        promoCode: null,
        tax: null,
    },
    purchaser: {},
    address: {},
    payment: {},
    paymentIntent: null,
    order: null,
    checkoutStep: 0,
    termsAccepted: false,
    formValidationErrors: null,
    validatedAddress: null,
    isMobile: false,
};
const getters = {
    getTestCountByTest: state => shortName => {
        return state.test_counts.find(test => test.item.short_name === shortName);
    },
    getTestCountById: state => id => {
        return state.test_counts.find(test => test.id === id);
    },
    paymentIntentAmountMatchesCartAmount: state => {
        // Need to divide payment intent amount by 100...amount gets pushed to stripe in cents
        return state.checkoutCart.totals.totalWithTax === (state.paymentIntent.amount / 100);
    }
};
const mutations = {
    updateTotalCount(state, payload) {
        state.total_count = payload.total_count;
    },
    updateTestCounts(state, payload) {
        state.test_counts = payload.test_counts;
    },
    updateTotalAmount(state, payload) {
        state.total_amount = payload.total_amount;
    },
    incrementTestCountBy(state, payload) {
        const test = state.test_counts.find(test => test.id === payload.testId);
        if (test !== undefined) {
            test.count += payload.count;
        }
    },
    incrementTotalCountBy(state, payload) {
        state.total_count += payload.count;
    },
    updateCheckoutCart(state, payload) {
        state.checkoutCart = payload;
    },
    updateTermsAccepted(state, payload) {
        state.termsAccepted = payload;
    },
    updateCheckoutStep(state, payload) {
        state.checkoutStep = payload;
    },
    updatePurchaser(state, payload) {
        state.purchaser = payload;
    },
    updateAddress(state, payload) {
        state.address = payload;
    },
    updatePayment(state, payload) {
        state.payment = payload;
    },
    updatePaymentIntent(state, payload) {
        state.paymentIntent = payload;
    },
    updateOrder(state, payload) {
        state.order = payload;
    },
    updateErrors(state, {payload, field}) {
        state.errors[field] = payload;
    },
    updateValidatedAddress(state, payload) {
        state.validatedAddress = payload;
    },
    removeErrors(state, field) {
        state.errors[field] = null;
    },
    updateIsMobile(state, payload) {
        state.isMobile = payload;
    },
};
const actions = {
    async updateCart({commit}, payload) {
        try {
            const response = await window.axios.post("/orders", {
                'test_type': payload.test_type,
                'order_count': payload.order_count,
                'language': payload.test_id
            });
            commit('updateTotalCount', {total_count: response.data.total_count});
            commit('updateTestCounts', {test_counts: response.data.test_counts});
            commit('updateTotalAmount', {total_amount: response.data.total_amount});
        } catch (e) {
            throw(e);
        }
    },
    async applyDiscount({state, commit, dispatch}, payload) {
        try {
            const {data} = await axios.post(route("orders.addCoupon"), {coupon_code: payload, address: state.address});
            commit('updateCheckoutCart', data.cart);
        } catch (e) {
            if (e.response.status === 404 || e.response.status === 400) {
                commit('updateErrors', {payload: e.response.data, field: 'promoCode'})
            }
            throw e;
        }
    },
    async getPurchaser({commit}) {
        try {
            let response = await axios.get(route("payment.get_purchaser"));
            commit('updatePurchaser', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async validatePurchaser({state, commit}) {
        try {
            await axios.post(route("payment.validate"), state.purchaser);
            commit('updateCheckoutStep', 1)
        } catch ({response}) {
            if (response.status === 422) {
                state.formValidationErrors = response.data.errors;
            } else {
                console.log(response);
            }
        }
    },
    async getAddress({commit}) {
        try {
            let response = await axios.get(route("payment.get_address"));
            commit('updateAddress', response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async validateAddress({state, commit}) {
        try {
            const {data} = await axios.post(route("payment.address.validate"), state.address);
            commit('updateValidatedAddress', data);

        } catch ({response}) {
            if (response.status === 422) {
                state.formValidationErrors = response.data.errors;
            } else if (response.status === 404) {
                // Error is related to country, line 1 of address or address type of unknown, all of which we are bypassing.
                commit('updateCheckoutStep', 2);
            } else if (response.status === 500) {
                commit('updateErrors', {payload: response.data, field: 'address'})
            } else {
                console.log(response);
            }
        }
    },
    async getTax({state, commit}) {
        try {
            const {data} = await axios.post(route("payment.tax"), {address: state.address});
            commit('updateCheckoutCart', data.cart);
        } catch ({response}) {
            // We are ignoring some validation from Avalara address resolution because it catches addresses that are valid. 
            // This means we sometimes let invalid addresses through which then fail on Avarara's tax endpoint.
            // In these cases although the error is from getting tax it is an address error.
            // See PaymentController@getTax for more context
            const addressRelatedErrorFaultSubCodes = [
                'RegionCodeError', 'CountryError', 'TaxAddressError', 'InvalidZipForState', 'ZipNotValidError', 'AddressRangeError', 'AddressError', 
            'InsufficientAddressError', 'PostalCodeError', 'UnsupportedCountryError', 'InvalidZipForStateError'
            ]
            if (addressRelatedErrorFaultSubCodes.includes(response.data.faultCode)) {
                commit('updateErrors', {payload: [{message: response.data.message}], field: 'address'})
                commit('updateCheckoutStep', 1)
            } else {
                commit('updateErrors', {payload: response.data, field: 'tax'})
            }
        }
    },
    async pingAvalara({commit}) {
        try {
            const {data} = await axios.post(route("payment.ping"));
        } catch ({response}) {
            commit('updateErrors', {payload: response.data, field: 'tax'})
        }
    },
    async createPaymentIntent({state, commit}) {
        try {
            let {data} = await axios.post("/payment/intent", {
                first_name: state.purchaser.firstName,
                last_name: state.purchaser.lastName,
                email: state.purchaser.email,
            });
            let {paymentIntent} = data;
            let {order} = data;
            commit('updatePaymentIntent', paymentIntent);
            commit('updateOrder', order);
        } catch (e) {
            commit('updateErrors', {
                payload: "There has been a problem preparing your payment. Your card has not been charged. Please try again or contact support",
                field: 'payment'
            })
            throw e;
        }
    },
    async updatePaymentIntent({state, commit}) {
        try {
            let {data} = await axios.put("/payment/intent", {
                payment_intent_id: state.paymentIntent.id,
                amount: state.checkoutCart.totals.totalWithTax,
            });
            commit('updatePaymentIntent', data);
        } catch (e) {
            commit('updateErrors', {
                payload: "There has been a problem preparing your payment. Your card has not been charged. Please try again or contact support",
                field: 'payment'
            })
            throw e;
        }
    },
    async activateOrder({state, commit}) {
        try {
            let {data} = await axios.post("/payment/checkout", {
                address: state.address,
                order: state.order.id,
                email: state.purchaser.email,
            });
        } catch (e) {
            commit('updateErrors', {
                payload: 'Your card has been charged, but there was an error activating the order. Please contact support.',
                field: 'payment'
            })
            throw e;
        }
    },
    order({state, commit}, payload) {
        if (payload.guest && state.total_count + payload.count > 20) {
            payload.count = 20 - state.total_count;
        }
        commit('incrementTestCountBy', {
            testId: payload.id,
            count: payload.count
        });
        commit('incrementTotalCountBy', {
            count: payload.count
        });
    },
    async refreshCart({commit}) {
        try {
            const response = await window.axios.get("/orders/get-cart/");
            commit('updateTotalCount', {total_count: response.data.total_count});
            commit('updateTestCounts', {test_counts: response.data.test_counts});
            commit('updateTotalAmount', {total_amount: response.data.total_amount});
        } catch (e) {
            throw(e);
        }
    },
    async updateTestInCart({state, commit}, payload) {
        try {
            const response = await window.axios.put("/orders/" + payload.test_id, {
                'count': payload.count
            });
            if (response.data.total_count !== state.total_count) {
                commit('updateTotalCount', {total_count: response.data.total_count});
                commit('updateTestCounts', {test_counts: response.data.test_counts});
                commit('updateTotalAmount', {total_amount: response.data.total_amount});
            }
            return true;
        } catch (e) {
            throw(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

