export default class Enneathought {

    static getServiceUrl() {
        return et_url;
    }

    static getSendingStats() {
        return window.axios.get(Enneathought.getServiceUrl()+'/sending-stats', {withCredentials: true});
    }

    static getPersonalities() {
        return window.axios.get('/administration/enneathought/personalities');
    }

    static getSubscriber(subscriberId) {
        return window.axios.get(Enneathought.getServiceUrl()+'/subscribers/'+subscriberId, {withCredentials: true});
    }

    static updateSubscriber(subscriberId, data) {
        return window.axios.put(Enneathought.getServiceUrl()+'/subscribers/'+subscriberId, data, {withCredentials: true});
    }

    static unsubscribe(subscriberId) {
        return window.axios.delete(Enneathought.getServiceUrl()+'/administration/subscribers/'+subscriberId, {withCredentials: true});
    }

    static subscribe(subscriberId) {
        return window.axios.put(Enneathought.getServiceUrl()+'/administration/subscribers/'+subscriberId, {}, {withCredentials: true});
    }

    static getTimezones() {
        return window.axios.get(Enneathought.getServiceUrl()+'/time-zones');
    }

    static getBounce(bounceId) {
        return window.axios.get(Enneathought.getServiceUrl()+'/bounces/'+bounceId, {withCredentials:true});
    }

    static getPromotion(promotionId) {
        return window.axios.get(Enneathought.getServiceUrl()+'/promotions/'+promotionId, {withCredentials:true});
    }

    static sendPromotionTestMessage(promotionId) {
        return window.axios.post(Enneathought.getServiceUrl()+'/administration/promotion-test/'+promotionId, {}, {withCredentials:true});
    }

    static createPromotion(data) {
        return window.axios.post(Enneathought.getServiceUrl()+'/promotions', data, {withCredentials:true});
    }

    static updatePromotion(promotionId, data) {
        return window.axios.put(Enneathought.getServiceUrl()+'/promotions/'+promotionId, data, {withCredentials:true});
    }

    static deletePromotion(promotionId) {
        return window.axios.delete(Enneathought.getServiceUrl()+'/promotions/'+promotionId, {withCredentials:true});
    }
}