export default class Event {
    static sendCreateEvent(event) {
        return window.axios.post('/administration/event', {
            name: event.name,
            date: event.date,
            contract_number: event.contract_number
        });
    }
    static sendUpdateEvent(event, eventId) {
        return window.axios.put('/administration/event/'+eventId, {
            name: event.name,
            date: event.date,
            contract_number: event.contract_number
        });
    }
    static getEvent(eventId) {
        return window.axios.get('/administration/event/'+eventId);
    }
}