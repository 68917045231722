<template>
  <div>
    <el-alert
      class="checkout-error"
      v-show="findErrorByKey('payment')"
      title="Payment Error"
      type="error"
      :description="findErrorByKey('payment')"
      show-icon
    >
    </el-alert>
    <el-alert
      class="checkout-error"
      v-show="findErrorByKey('address')"
      title="Address Error"
      type="error"
      show-icon
    >
      <ul>
        <li
          v-if="findErrorByKey('address')"
          v-for="error in findErrorByKey('address')"
        >
          {{ error.message }}
        </li>
      </ul>
    </el-alert>
    <PurchaserForm
      v-if="checkoutStep === 0"
      @validate="$emit('validatePurchaser')"
      :organizations="organizations"
    />
    <AddressForm
      v-if="checkoutStep === 1"
      @validate="$emit('validateAddress')"
    />
    <PaymentForm v-if="checkoutStep === 2" @pay="$emit('pay')" :terms="terms" />
  </div>
</template>

<script>
import PurchaserForm from "./PurchaserForm.vue";
import AddressForm from "./AddressForm.vue";
import PaymentForm from "./PaymentForm.vue";

import { mapMutations, mapState } from "vuex";
import AddressVerification from "./AddressVerification.vue";

export default {
  name: "CheckoutForm.vue",
  components: {
    AddressVerification,
    PurchaserForm,
    AddressForm,
    PaymentForm,
  },
  data() {
    return {
      form: {
        terms: false,
      },
    };
  },
  props: {
    terms: Object,
    organizations: Array,
  },
  computed: {
    ...mapState("cart", ["errors", "termsAccepted", "checkoutStep"]),
  },
  methods: {
    findErrorByKey(key) {
      return this.errors[key];
    },
    ...mapMutations("cart", ["updateTermsAccepted", "updateIsMobile"]),
  },
  created() {
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth < 750) {
      this.updateIsMobile(true);
    } else {
      this.updateIsMobile(false);
    }
    window.addEventListener("resize", () => {
      viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth < 750) {
        this.updateIsMobile(true);
      } else {
        this.updateIsMobile(false);
      }
    }, false);
  },
};
</script>

<style scoped>
.checkout-error {
  margin-bottom: 10px;
}
</style>
