<template>
  <div>
    <div v-if="loading" style="text-align: center">
      <loading-indicator />
    </div>
    <div class="container" v-else>
      <form>
        <input type="hidden" v-model="recipient_type" />
        <input type="hidden" v-model="notification.subject" />
        <input type="hidden" v-model="notification.body" />
        <input type="hidden" v-model="recipients" />
        <div class="form-group">
          <button
            class="btn btn-default"
            @click.prevent="sendTestNotification()"
          >
            Resend test notification to logged in user
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { markdownEditor } from "vue-simplemde";
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import httpNotification from "../http/Notification.js";

export default {
  props: {
    recipient_input: false,
    subject_input: false,
    body_input: null,
    recipient_count_input: 0,
  },
  data() {
    return {
      notification: {
        ind: null,
        org: null,
        subject: this.subject_input,
        body: this.body_input,
      },
      configs: {
        toolbar: false,
        status: false,
      },
      recipient_type: this.recipient_input,
      loading: false,
      recipients: this.recipient_count_input,
    };
  },
  components: {
    LoadingIndicator,
  },
  methods: {
    sendTestNotification() {
      this.calculateRecipientType();
      this.loading = true;
      httpNotification
        .sendTestEmail(this.notification)
        .then(({ data }) => {
          window.toastr.success(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    createMessageArrayFromValidationError(errorObj) {
      let messages = [];
      Object.keys(errorObj).forEach((key) => {
        errorObj[key].forEach((error) => {
          messages.push(error);
        });
      });
      return messages;
    },
    calculateRecipientType() {
      this.notification.ind =
        this.recipient_type === "Individual" || this.recipient_type === "Both";
      this.notification.org =
        this.recipient_type === "Organization" ||
        this.recipient_type === "Both";
    },
  },
};
</script>
