<template>
    <div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">Enneathought List</h3>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-sm-3">
                        <input class="form-control" v-model="search" placeholder="Search"/>
                    </div>
                    <div class="col-sm-9">
                        <label class="checkbox-inline" v-for="(name, id) in personalities">
                            <input type="checkbox" v-model="searchPersonalities" :value="id"> {{ name }}
                        </label>
                    </div>
                </div>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="clickable" @click="setSort('day')">Day</th>
                        <th class="clickable" @click="setSort('personality_id')">Type</th>
                        <th class="clickable" @click="setSort('content')">Content</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="enneathought in enneathoughts">
                        <td nowrap="nowrap">
                            {{ enneathought.day }}
                        </td>
                        <td>
                            {{ enneathought.personality_id }}
                        </td>
                        <td v-html="enneathought.content">
                        </td>
                    </tr>
                    </tbody>
                </table>
                <v-paginator :resource_url="enneathoughtUrl" @update="updateEnneathoughts"></v-paginator>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
<script>
    import VuePaginator from 'vuejs-paginator';
    import httpEnneathought from '../../http/Enneathought';
    export default{
        data(){
            return{
                enneathoughts: [],
                personalities: [],
                searchPersonalities: [],
                search: '',
                sort: 'day',
                sortDir: 'ASC',
            }
        },
        components: {
            VPaginator: VuePaginator
        },
        computed: {
            enneathoughtUrl() {
                return (httpEnneathought.getServiceUrl()+'/enneathoughts?search=' + this.search
                + '&sort=' + this.sort
                + '&sortDir=' + this.sortDir
                + '&searchPersonalities=' + JSON.stringify(this.searchPersonalities));
            }
        },
        methods: {
            setSort(field) {
                if(this.sort === field) {
                    this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
                }else {
                    this.sort = field;
                    this.sortDir = 'ASC';
                }
            },
            fetchPersonalities() {
                httpEnneathought.getPersonalities().then(({data}) => {
                    this.personalities = data;
                    this.searchPersonalities = Object.keys(data);
                });
            },
            updateEnneathoughts(data) {
                this.enneathoughts = data;
            }
        },
        mounted() {
            this.fetchPersonalities();
        }
    }
</script>
