<template>
    <div>
        <div class="text-center" v-if="loading">
            <loading-indicator />
        </div>
        <div v-else>
            <div class="form-group">
                <label for="test">Test</label>
                <select name="test" id="test" class="form-control" v-model="formData.test">
                    <option v-for="test_option in test_options" :value="test_option">{{test_option.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="number_of_codes">Number of Codes</label>
                <input type="number" name="number_of_codes" id="number_of_codes" class="form-control" placeholder="# of Codes" v-model="formData.codes" />
            </div>
            <div class="form-group">
                <label for="reason">Reason</label>
                <select name="reason" id="reason" class="form-control" v-model="formData.reason">
                    <option disabled value="">Select a reason</option>
                    <option v-for="reason_option in reason_options" :value="reason_option">{{reason_option.name}}</option>
                </select>
            </div>
            <div class="form-group" v-if="formData.reason && formData.reason.name === 'Other'">
                <label for="reason_other">Other</label>
                <input type="text" name="reason_other" id="reason_other" v-model="formData.reasonOther" class="form-control" />
            </div>
            <div class="form-group" v-if="formData.reason && formData.reason.is_event">
                <label for="event">Event Name</label>
                <select name="event" id="event" v-model="formData.event" class="form-control">
                    <option disabled value="">Select an event</option>
                    <option v-for="event_option in event_options" :value="event_option">{{event_option.name}} - {{formatDate(event_option.date)}}</option>
                    <option value="add_event">Add new event</option>
                </select>
            </div>
            <div v-if="formData.reason && formData.reason.is_event && formData.event === 'add_event'" class="panel panel-default">
                <div class="panel-body">
                    <admin-event :isEmbedded="true" @eventCreated="eventCreated"></admin-event>
                </div>
            </div>
            <div class="form-group">
                <button class="btn btn-default" @click.prevent="submit()"><span class="glyphicon glyphicon-plus-sign"></span> Add Codes</button>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import AdminEvent from "./AdminEvent.vue";

    export default {
        data() {
            return {
                loading: false,
                formData: {
                    test: null,
                    codes: null,
                    reason: null,
                    reasonOther: null,
                    event: null
                }
            }
        },
        props: [
            "test_options",
            "reason_options",
            "organization_id",
            "event_options"
        ],
        components: {
            LoadingIndicator,
            AdminEvent
        },
        methods: {
            submit() {
                this.loading = true;
                window.axios.post("/administration/organization/" + this.organization_id + "/add_test_codes", {
                    'test': this.formData.test.id,
                    'number_of_codes': this.formData.codes,
                    'reason': this.formData.reason,
                    'reason_other': this.formData.reasonOther,
                    'event': this.formData.event
                }).then(({data}) => {
                    window.location.reload();
                }).catch(({response}) => {
                    if(response.status === 422) {
                        this.createMessageArrayFromValidationError(response.data).forEach((errorMessage) => {
                            window.toastr.error(errorMessage);
                        });
                    } else {
                        window.toastr.error('Error processing request');
                    }
                    this.loading = false;
                });
            },
            createMessageArrayFromValidationError(errorObj) {
                let messages = [];
                Object.keys(errorObj).forEach((key) => {
                    errorObj[key].forEach((error) => {
                        messages.push(error);
                    });
                });
                return messages;
            },
            eventCreated: function(event) {
                this.formData.event = event;
                this.event_options.push(event);
            },            
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('MM/DD/YYYY');
                }
                return '';
            },

        },
        created() {
            if (this.test_options.length === 1) {
                this.formData.test = this.test_options[0];
            }
        }
    }
</script>
