<template>
  <el-form :label-position="isMobile ? 'top' : 'left'" label-width="200px">
    <el-form-item
      v-if="organizations && organizations.length > 0"
      label="Organization"
    >
      <div>{{ organizations[0].organization_name }}</div>
    </el-form-item>
    <el-form-item label="First Name" :error="getValidationError('firstName')">
      <el-input
        label="First Name"
        v-model="form.firstName"
        @input="clearValidationError('firstName')"
        autocomplete="given-name"
      ></el-input>
    </el-form-item>
    <el-form-item label="Last Name" :error="getValidationError('lastName')">
      <el-input
        label="Last Name"
        v-model="form.lastName"
        @input="clearValidationError('lastName')"
        autocomplete="family-name"
      ></el-input>
    </el-form-item>
    <el-form-item label="Email Address" :error="getValidationError('email')">
      <el-input
        label="Email Address"
        v-model="form.email"
        @input="clearValidationError('email')"
        type="email"
        autocomplete="email"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="Confirm Email Address"
      :error="getValidationError('email_confirmation')"
    >
      <el-input
        label="Confirm Email Address"
        v-model="form.email_confirmation"
        @input="clearValidationError('email_confirmation')"
        type="email"
      ></el-input>
    </el-form-item>
    <el-form-item class="form-btn-group">
      <el-button
        type="primary"
        @click="$emit('validate')"
        :disabled="findErrorByKey('tax') !== null"
        >Next</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import FormValidation from "../../mixins/FormValidation";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "PurchaserForm",
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        email_confirmation: "",
      },
    };
  },
  props: {
    organizations: Array,
  },
  mixins: [FormValidation],
  computed: {
    ...mapState("cart", [
      "formValidationErrors",
      "purchaser",
      "errors",
      "isMobile",
    ]),
  },
  methods: {
    ...mapMutations("cart", ["updatePurchaser"]),
    ...mapActions('cart', ['getPurchaser']),
    findErrorByKey(key) {
      return this.errors[key];
    },
  },
  watch: {
    form: {
      handler(newData) {
        this.updatePurchaser(newData);
      },
      deep: true,
    },
    formValidationErrors: {
      handler(errors) {
        this.validationErrors = errors;
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getPurchaser();
    this.form.firstName = this.purchaser.firstName;
    this.form.lastName = this.purchaser.lastName;
    this.form.email = this.purchaser.email;
    this.form.email_confirmation = this.purchaser.email_confirmation;
  },
};
</script>

<style scoped></style>
