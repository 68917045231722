<template>
  <div>
    <h4 aria-label="Billing Address">Billing Address</h4>
    <el-form :label-position="isMobile ? 'top' : 'left'" label-width="200px">
      <el-form-item
          :error="getValidationError('country')"
          class="label-black"
          for="country"
          label="Country"
      >
        <el-select
            id="country"
            ref="country"
            v-model="form.country"
            autocomplete="off"
            filterable
            label="Country"
            placeholder="Select Country"
            @input="clearValidationError('country')"
        >
          <el-option
              v-for="country in countries"
              :key="country.code"
              :aria-label="country.name"
              :label="country.name"
              :value="country.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          :error="getValidationError('line_1')"
          class="label-black"
          label="Address Line 1"
      >
        <el-input
            v-model="form.line_1"
            autocomplete="address-line1"
            label="Address Line 1"
            @input="clearValidationError('line_1')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :error="getValidationError('line_2')"
          class="label-black"
          label="Address Line 2"
      >
        <el-input
            v-model="form.line_2"
            autocomplete="address-line2"
            label="Address Line 2"
            @input="clearValidationError('line_2')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :error="getValidationError('city')"
          class="label-black"
          label="City"
      >
        <el-input
            v-model="form.city"
            autocomplete="address-level2"
            label="City"
            @input="clearValidationError('city')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :error="getValidationError('state')"
          class="label-black"
          for="state"
          label="State / Province / Region"
      >
        <el-select
            v-if="showStatesAsDropdown"
            id="state"
            v-model="form.state"
            :disabled="form.country === '' || form.country === undefined"
            autocomplete="off"
            filterable
            label="State / Province / Region"
            placeholder="Select State / Province / Region"
            @input="clearValidationError('state')"
        >
          <el-option
              v-for="state in states"
              v-if="form.country === 'US'"
              :label="state.name"
              :value="state.code"
          />
          <el-option
              v-for="province in provinces"
              v-if="form.country === 'CA'"
              :label="province.name"
              :value="province.code"
          />
        </el-select>
        <el-input
            v-else
            id="state"
            v-model="form.state"
            :disabled="form.country === '' || form.country === undefined"
            @input="clearValidationError('state')"
        ></el-input>
      </el-form-item>
      <el-form-item
          :error="getValidationError('zip')"
          class="label-black"
          label="Zip / Postal Code"
      >
        <el-input
            v-model="form.zip"
            autocomplete="postal-code"
            label="Zip / Postal Code"
            type="tel"
            @input="clearValidationError('zip')"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-btn-group">
        <el-button type="secondary" @click="back">Back</el-button>
        <el-button type="primary" @click="$emit('validate')">Next</el-button>
      </el-form-item>
    </el-form>
    <el-dialog v-model="dialogVisible" title="Verify Your Address" width="50%">
      <div slot="header">
        The address you've entered can be adjusted to better match postal
        service records.
      </div>
      <AddressVerification
          v-if="validatedAddress"
          :original-address="form"
          :validated-address="validatedAddress"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="keepAddress">Use Address You Entered</el-button>
          <el-button type="primary" @click="useRecommendedAddress"
          >Use Recommended Address
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import FormValidation from "../../mixins/FormValidation";
import countries from "../../../countries.json";
import states from "../../../states.json";
import provinces from "../../../provinces.json";
import AddressVerification from "./AddressVerification.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AddressForm",
  components: {AddressVerification},
  data() {
    return {
      countries: countries,
      states: states,
      provinces: provinces,
      dialogVisible: false,
      isMounted: false,
      form: {
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
    };
  },
  mixins: [FormValidation],
  computed: {
    ...mapState("cart", [
      "validatedAddress",
      "formValidationErrors",
      "address",
      "isMobile",
    ]),
    showStatesAsDropdown() {
      return this.form.country === "US" || this.form.country === "CA";
    },
  },
  methods: {
    next() {
      this.removeErrors("address");
      this.updateCheckoutStep(2);
    },
    back() {
      this.removeErrors("address");
      this.updateCheckoutStep(0);
    },
    keepAddress() {
      this.dialogVisible = false;
      this.next();
    },
    useRecommendedAddress() {
      this.form.line_1 = this.validatedAddress.line_1;
      this.form.line_2 = this.validatedAddress.line_2;
      this.form.city = this.validatedAddress.city;
      this.form.state = this.validatedAddress.state;
      this.form.zip = this.validatedAddress.zip;
      this.form.country = this.validatedAddress.country;
      this.dialogVisible = false;
      this.next();
    },
    diffAddress() {
      const difference = Object.keys(this.form).filter(
          (k) => this.form[k] !== this.validatedAddress[k]
      );

      if (difference.length > 0) {
        this.dialogVisible = true;
      } else {
        this.next();
      }
    },
    ...mapMutations("cart", [
      "updateCheckoutStep",
      "updateAddress",
      "updateValidatedAddress",
      "removeErrors",
    ]),
    ...mapActions("cart", [
        "getAddress"
    ]),
    focusCountry() {
      this.$refs.country.focus();
    },
  },
  watch: {
    form: {
      handler(newData) {
        this.updateAddress(newData);
      },
      deep: true,
    },
    formValidationErrors: {
      handler(errors) {
        this.validationErrors = errors;
      },
      deep: true,
    },
    validatedAddress: {
      handler(address) {
        if (address && this.isMounted) {
          this.diffAddress();
        }
      },
      deep: true,
    },
  },
  async mounted() {
      this.updateValidatedAddress({}); 
      await this.getAddress();
      this.form.line_1 =
      this.address.line_1 !== undefined ? this.address.line_1 : "";
      this.form.line_2 =
      this.address.line_2 !== undefined ? this.address.line_2 : "";
      this.form.city = this.address.city !== undefined ? this.address.city : "";
      this.form.state =
      this.address.state !== undefined ? this.address.state : "";
      this.form.zip = this.address.zip !== undefined ? this.address.zip : "";
      this.form.country =
      this.address.country !== undefined ? this.address.country : "";
    this.focusCountry();
    this.isMounted = true;
  },
};
</script>
