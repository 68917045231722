<template>
  <div style="min-width: 1000px">
    <div class="container-fluid">
      <el-row style="margin-bottom: 20px">
        <el-col :span="8">
          <label class="control-label">Limit records by date:</label>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            placeholder="ElSelect date range"
            @change="filterListByDate"
          ></el-date-picker>
        </el-col>
        <el-col :span="8">
          <label>Filter payouts by:</label>
          <el-select
            v-model="showPayouts"
            @change="filterChanged"
            style="width: 500px"
          >
            <el-option
              v-for="item in ElOptions"
              :value="item.value"
              :label="item.label"
              :key="item.value"
              class="custom"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <reconciliation-list
            ref="list"
            :loading="listLoading"
            :tableData="payoutList"
            :format="formatCurrencyElCol"
            @selectedChanged="setElSelected"
          ></reconciliation-list>
        </el-col>
        <el-col :span="16">
          <span v-show="ElSelectedID">
            <div v-if="detailLoading" style="text-align: center">
              <loading-indicator />
            </div>
            <div v-else>
              <el-row v-if="Object.keys(payoutDetails).length > 1">
                <el-col :span="24">
                  <reconciliation-detail-summary
                    :details="payoutDetails"
                    @reconcile="reconcile"
                    @unreconcile="unreconcile"
                  ></reconciliation-detail-summary>
                </el-col>
                <el-col :span="24">
                  <reconciliation-detail
                    :payoutDetails="payoutDetails.transactions"
                    :format="formatCurrencyElCol"
                  ></reconciliation-detail>
                </el-col>
              </el-row>
            </div>
          </span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import { ElRow, ElCol, ElDatePicker, ElSelect, ElOption } from "element-plus";
import httpReconciliation from "../../http/Reconciliation";

export default {
  data() {
    return {
      listLoading: false,
      detailLoading: false,
      payoutList: [],
      ElSelectedID: null,
      payoutDetails: {
        transactions: [],
      },
      dateRange: [],
      showPayouts: "all",
      ElOptions: [
        {
          value: 1,
          label: "Reconciled Only",
        },
        {
          value: 0,
          label: "Unreconciled Only",
        },
        {
          value: "all",
          label: "All",
        },
      ],
    };
  },
  components: {
    LoadingIndicator,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElRow,
    ElCol,
  },

  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.listLoading = true;
      httpReconciliation
        .getList(this.showPayouts)
        .then(({ data }) => {
          this.payoutList = data;
          this.listLoading = false;
        })
        .catch(({ response }) => {
          //
        });
    },
    filterListByDate(date) {
      this.listLoading = true;
      if (date) {
        let startDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        startDate.setUTCHours(0);
        endDate.setUTCHours(0);
        httpReconciliation
          .getListFilteredByDate(
            startDate.toISOString(),
            endDate.toISOString(),
            this.showPayouts
          )
          .then(({ data }) => {
            this.payoutList = data;
            this.listLoading = false;
          });
      } else {
        this.getList();
      }
    },
    getDetails() {
      this.detailLoading = true;
      httpReconciliation
        .getDetails(this.ElSelectedID)
        .then(({ data }) => {
          if (data.status && data.status === "processing") {
            window.toastr.success(data.message);
          } else {
            this.payoutDetails = data;
          }
          this.detailLoading = false;
        })
        .catch((response) => {
          this.detailLoading = false;
          console.log(response);
        });
    },
    setElSelected(val) {
      this.ElSelectedID = val;
      this.getDetails();
    },
    formatCurrencyElCol(ElRow, ElCol) {
      return ElRow[ElCol.property] < 0
        ? "$(" + Math.abs(ElRow[ElCol.property]).toFixed(2) + ")"
        : "$" + ElRow[ElCol.property].toFixed(2);
    },
    reconcile(id) {
      this.listLoading = true;
      let startDate = this.dateRange[0] ? new Date(this.dateRange[0]) : "";
      let endDate = this.dateRange[1] ? new Date(this.dateRange[1]) : "";
      if (startDate) {
        startDate.setUTCHours(0, 0, 0, 0);
        startDate = startDate.toISOString();
      }
      if (endDate) {
        endDate.setUTCHours(0, 0, 0, 0);
        endDate = endDate.toISOString();
      }
      httpReconciliation
        .reconcile(id, startDate, endDate, this.showPayouts)
        .then(({ data }) => {
          this.payoutList = data;
          this.setElSelected(id);
          this.listLoading = false;
        });
    },
    unreconcile(id) {
      this.listLoading = true;
      let startDate = this.dateRange[0] ? new Date(this.dateRange[0]) : "";
      let endDate = this.dateRange[1] ? new Date(this.dateRange[1]) : "";
      if (startDate) {
        startDate.setUTCHours(0, 0, 0, 0);
        startDate = startDate.toISOString();
      }
      if (endDate) {
        endDate.setUTCHours(0, 0, 0, 0);
        endDate = endDate.toISOString();
      }
      httpReconciliation
        .unreconcile(id, startDate, endDate, this.showPayouts)
        .then(({ data }) => {
          this.payoutList = data;
          this.setElSelected(id);
          this.listLoading = false;
        });
    },
    filterChanged() {
      if (this.dateRange[0]) {
        this.filterListByDate(true);
      } else {
        this.getList();
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
<style scoped>
custom {
  display: flex;
  width: 300px;
}
</style>
