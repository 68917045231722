<template>
  <div>
    <div v-if="loading" style="text-align: center">
      <loading-indicator />
    </div>
    <div class="container" v-else>
      <form>
        <div class="form-group">
          <label class="radio-inline">
            <input type="radio" v-model="recipient_type" value="ind" />
            Individual Accounts Only
          </label>
          <label class="radio-inline">
            <input type="radio" v-model="recipient_type" value="org" />
            Organization Accounts Only
          </label>
          <label class="radio-inline">
            <input type="radio" v-model="recipient_type" value="both" /> All
            Accounts
          </label>
        </div>
        <div class="form-group">
          <label>Recipients</label>
          <div ref="test" class="form-control-static">{{ recipients }}</div>
        </div>
        <div class="form-group">
          <label>Subject</label>
          <input
            type="text"
            class="form-control"
            v-model="notification.subject"
          />
        </div>
        <div class="form-group">
          <label>Body</label>
          <VMarkdownEditor
            ref="markdownEditor"
            v-model="notification.body"
            :configs="configs"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary" @click.prevent="confirmSend()">
            Send Notification
          </button>
          <button
            class="btn btn-default"
            @click.prevent="sendTestNotification()"
          >
            Send Test notification to logged in user
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { VMarkdownEditor } from "vue3-markdown";
import "vue3-markdown/dist/style.css";
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import httpNotification from "../http/Notification.js";

export default {
  data() {
    return {
      notification: {
        ind: false,
        org: false,
        subject: null,
        body: null,
      },
      configs: {
        hideIcons: ["quote", "side-by-side", "fullscreen"],
      },
      recipient_type: null,
      loading: false,
      recipients: 0,
    };
  },
  components: {
    LoadingIndicator,
    VMarkdownEditor,
  },
  watch: {
    recipient_type: function () {
      this.notification.ind =
        this.recipient_type === "ind" || this.recipient_type === "both";
      this.notification.org =
        this.recipient_type === "org" || this.recipient_type === "both";
      this.calculateRecipients();
    },
  },
  computed: {
    simplemde() {
      return this.$refs.markdownEditor.simplemde;
    },
  },
  methods: {
    sendTestNotification() {
      this.loading = true;
      httpNotification
        .sendTestEmail(this.notification)
        .then(({ data }) => {
          window.toastr.success(data);
          this.loading = false;
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    confirmSend() {
      sweetAlert({
        title: "Are you sure?",
        text:
          "You are about to send this email to " +
          this.recipients +
          (this.recipients == 1 ? " recipient." : " recipients."),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#52924a",
        confirmButtonText: "Send the email",
        closeOnConfirm: true,
      }).then(() => {
        this.sendNotification();
      });
    },
    sendNotification() {
      this.loading = true;
      httpNotification
        .sendEmail(this.notification)
        .then(({ data }) => {
          window.toastr.success(data);
          this.notification.ind = false;
          this.notification.org = false;
          this.notification.subject = null;
          this.notification.body = null;
          this.recipients = 0;
          this.loading = false;
        })
        .catch(({ response }) => {
          if (response.status == 422) {
            this.createMessageArrayFromValidationError(response.data).forEach(
              (errorMessage) => {
                window.toastr.error(errorMessage);
              }
            );
          } else {
            window.toastr.error("Error processing request");
          }
          this.loading = false;
        });
    },
    createMessageArrayFromValidationError(errorObj) {
      let messages = [];
      Object.keys(errorObj).forEach((key) => {
        errorObj[key].forEach((error) => {
          messages.push(error);
        });
      });
      return messages;
    },
    calculateRecipients() {
      httpNotification
        .calculateRecipients(this.notification.ind, this.notification.org)
        .then(({ data }) => {
          this.recipients = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
};
</script>
