<template>
  <div>
    <form class="form-horizontal">
      <div class="checkbox">
        <label>
          <input type="checkbox" name="tax_exempt" value="true" v-model="form.tax_exempt"> Apply for Tax Exemption
          <p class="validation-error" v-for="error in errors.tax_exempt">{{error}}</p>
        </label>
      </div>
      <div class="form-group">
        <label for="country" class="col-sm-3 control-label">Country</label>
        <div class="col-sm-9">
          <select name="country" id="country" class="form-control" v-model="form.country">
            <option v-for="country in filteredCountries" :value="country.code">{{ country.name }}</option>
          </select>
          <p class="validation-error" v-for="error in errors.country">{{error}}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="line_1" class="col-sm-3 control-label">Address Line 1</label>
        <div class="col-sm-9">
          <input id="line_1" name="line_1" type="text" class="form-control" v-model="form.line_1">
          <p class="validation-error" v-for="error in errors.line_1">{{error}}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="line_2" class="col-sm-3 control-label">Address Line 2</label>
        <div class="col-sm-9">
          <input id="line_2" name="line_2" type="text" class="form-control" v-model="form.line_2">
          <p class="validation-error" v-for="error in errors.line_2">{{error}}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="city" class="col-sm-3 control-label">City</label>
        <div class="col-sm-9">
          <input id="city" name="city" type="text" class="form-control" v-model="form.city">
          <p class="validation-error" v-for="error in errors.city">{{error}}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="state" class="col-sm-3 control-label">State / Province / Region</label>
        <div class="col-sm-9">
          <select
              id="state"
              name="state"
              class="form-control"
              v-model="form.state"
              v-if="showStatesAsDropdown">
            <option
                v-if="form.country === 'US'"
                v-for="state in states"
                :value="state.code">
              {{ state.name }}
            </option>
            <option
                v-if="form.country === 'CA'"
                v-for="province in provinces"
                :value="province.code">
              {{ province.name }}
            </option>
          </select>
          <template v-else>
            <input
              id="state"
              name="state"
              class="form-control"
              v-model="form.state"
              maxlength="3" />
            <p>Please enter a valid State/Region/Province code <b>(no more than 3 characters)</b></p>
          </template>
          <p class="validation-error" v-for="error in errors.state">{{error}}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="zip" class="col-sm-3 control-label">Zip / Postal Code</label>
        <div class="col-sm-9">
          <input id="zip" name="zip" type="text" class="form-control" v-model="form.zip">
          <p class="validation-error" v-for="error in errors.zip">{{error}}</p>
        </div>
      </div>
    </form>
    <div class="form-group">
      <div class="col-sm-8 col-sm-offset-3">
        <button @click="submit" class="btn btn-default"><span class="glyphicon glyphicon-floppy-disk"></span> Save Changes</button>
        <a class="text-muted" :href="accountIndex">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
import countries from "../../countries.json";
import states from "../../states.json";
import provinces from "../../provinces.json";

export default {
  name: "TaxExemptForm",
  data() {
    return {
      form: {
        tax_exempt: false,
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
      },
      errors: {},
      countries: countries,
      states: states ,
      provinces: provinces ,
    }
  },
  methods: {
    async submit() {
      try {
        const {data} = await axios.post(route('account.tax-exempt.update'), {
          tax_exempt: this.form.tax_exempt,
          line_1: this.form.line_1,
          line_2: this.form.line_2,
          city: this.form.city,
          state: this.form.state,
          zip: this.form.zip,
          country: this.form.country,
        });

        if(data.success) {
          window.location.href = this.accountIndex + '?exempt=pending'
        }

      } catch ({response}) {
        if (response.status === 422) {
          this.errors = response.data.errors;
        } else if (response.data.failed) {
          window.location.href = this.accountIndex + '?exempt=failed'
        }
      }
    }
  },
  computed: {
    filteredCountries() {
      return this.countries.filter((country) => {
        return country.code === "US" || country.code === "CA";
      });
    },
    accountIndex() {
      return route('account.index');
    },
    showStatesAsDropdown() {
      return (
          this.form.country === "US" || this.form.country === "CA"
      );
    },
  },
}
</script>

<style scoped>
.validation-error {
  color: red;
  font-size: 12px;
}
</style>