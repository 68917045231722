<template>
  <div class="container-fluid">
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <label>Select a test:</label>
        <el-select
          v-model="test"
          placeholder="Select a test"
          @change="getSummary"
        >
          <el-option
            v-for="item in tests"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <label>Select a date range:</label>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          placeholder="Select a date range"
          :picker-options="picker_options"
          @change="getSummary"
        ></el-date-picker>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="pdfDownload">Download PDF</el-button>
        <el-button type="primary" @click="csvExport">Export to CSV</el-button>
      </el-col>
    </el-row>
    <el-row v-if="summary || reportLoading">
      <report-summary
        :summary="summary"
        :loading="reportLoading"
      ></report-summary>
    </el-row>
  </div>
</template>
<script>
import { ElDatePicker, ElRow, ElCol } from "element-plus";
import httpReconciliation from "../../http/Reconciliation";
import ReportSummary from "./ReportSummary.vue";

export default {
  data() {
    return {
      tests: [
        {
          value: "",
          label: "** All Tests **",
        },
        {
          value: "RHETI_EN",
          label: "RHETI English",
        },
        {
          value: "IVQ_EN",
          label: "IVQ English",
        },
      ],
      START_DATES: [
        {
          year: 0,
          month: 0,
          day: 1,
        },
        {
          year: 0,
          month: 3,
          day: 1,
        },
        {
          year: 0,
          month: 6,
          day: 1,
        },
        {
          year: 0,
          month: 9,
          day: 1,
        },
      ],
      END_DATES: [
        {
          year: 0,
          month: 2,
          day: 31,
        },
        {
          year: 0,
          month: 5,
          day: 30,
        },
        {
          year: 0,
          month: 8,
          day: 30,
        },
        {
          year: 0,
          month: 11,
          day: 31,
        },
      ],
      dateRange: [],
      test: "",
      summary: null,
      reportLoading: false,
    };
  },
  components: {
    ElDatePicker,
    ElRow,
    ElCol,
    ReportSummary,
  },
  methods: {
    get_quarters_near_date(date, numberOfQuarters) {
      const result = [];
      for (let i = 0; i < numberOfQuarters; i++) {
        const quarter_number = this.quarter_of_year(date);
        const startDate = this.START_DATES[quarter_number - 1];
        startDate.year = date.getFullYear();
        const endDate = this.END_DATES[quarter_number - 1];
        endDate.year = date.getFullYear();
        const quarter = {
          text: this.quarter_label(quarter_number, date),
          onClick(picker) {
            const start = startDate;
            const end = endDate;
            console.log(start, end);
            picker.$emit("pick", [
              new Date(start.year, start.month, start.day),
              new Date(end.year, end.month, end.day),
            ]);
          },
        };
        result.push(quarter);
        date.setMonth(date.getMonth() - 3);
      }
      console.log(result);
      return result;
    },
    quarter_of_year(date) {
      let month = date.getMonth() + 1;
      return Math.ceil(month / 3);
    },
    quarter_label(quarter, date) {
      return quarter + "Q" + date.getFullYear();
    },
    getSummary(newValue) {
      if (this.dateRange.length === 2) {
        this.reportLoading = true;
        let startDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(0, 0, 0, 0);
        httpReconciliation
          .getReportSummary(
            startDate.toISOString(),
            endDate.toISOString(),
            this.test
          )
          .then(({ data }) => {
            this.summary = data;
            this.reportLoading = false;
          });
      }
    },
    csvExport() {
      if (this.dateRange[0]) {
        let startDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(0, 0, 0, 0);
        window.location =
          "/administration/report/payouts/export?startDate=" +
          startDate.toISOString() +
          "&endDate=" +
          endDate.toISOString() +
          "&test=" +
          this.test;
      } else {
        toastr.warning(
          "You must select a date range before you can export data."
        );
      }
    },
    pdfDownload() {
      if (this.dateRange[0]) {
        let startDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(0, 0, 0, 0);
        window.location =
          "/administration/report/payouts/pdf?startDate=" +
          startDate.toISOString() +
          "&endDate=" +
          endDate.toISOString() +
          "&test=" +
          this.test;
      } else {
        toastr.warning(
          "You must select a date range before you can download PDF."
        );
      }
    },
  },
  computed: {
    picker_options() {
      const date = new Date();
      date.setUTCHours(0, 0, 0, 0);
      return {
        shortcuts: this.get_quarters_near_date(date, 4),
      };
    },
  },
};
</script>
