<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Bounce List</h3>
        </div>
        <div class="panel-body">
            <div class="row">
                <div class="col-sm-3">
                    <input class="form-control" v-model="search" placeholder="Search"/>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="clickable" @click="setSort('notification_type')">Notification Type</th>
                    <th class="clickable" @click="setSort('bounce_type')">Bounce Type</th>
                    <th class="clickable" @click="setSort('created_at')">Received At</th>
                    <th class="clickable" @click="setSort('email')">Email</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="bounce in bounces">
                    <td>{{ bounce.notification_type }}</td>
                    <td>{{ bounce.bounce_type}}</td>
                    <td>{{ bounce.created_at }}</td>
                    <td>{{ bounce.email }}</td>
                    <td>
                        <a class="btn btn-md btn-primary" :href="'/administration/enneathought/bounces/' + bounce.id">
                            View
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <v-paginator :resource_url="bouncesUrl" @update="updateBounces"></v-paginator>
        </div>
    </div>
</template>
<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
<script>
    import VuePaginator from 'vuejs-paginator';
    import httpEnneathought from '../../http/Enneathought';

    export default{
        data(){
            return{
                bounces: [],
                search: '',
                sort: 'created_at',
                sortDir: 'ASC'
            }
        },
        components: {
            VPaginator: VuePaginator
        },
        computed: {
            bouncesUrl() {
                return (httpEnneathought.getServiceUrl()+'/bounces?search=' + this.search
                        + '&sort=' + this.sort
                        + '&sortDir=' + this.sortDir);
            }
        },
        methods: {
            updateBounces(data){
                this.bounces = data;
            },
            setSort(field) {
                if(this.sort === field) {
                    this.sortDir = this.sortDir === 'ASC' ? 'DESC' : 'ASC';
                }else {
                    this.sort = field;
                    this.sortDir = 'ASC';
                }
            }
        }
    }
</script>
