export default class Reconciliation {
    static getList(reconciledStatus) {
        return window.axios.get('/administration/api/reconciliation?reconciledStatus='+reconciledStatus);
    }

    static getListFilteredByDate(startDate, endDate, reconciledStatus) {
        return window.axios.get('/administration/api/reconciliation?startDate='+startDate+"&endDate="+endDate+"&reconciledStatus="+reconciledStatus);
    }

    static reconcile(id, startDate, endDate, reconciledStatus) {
        return window.axios.post('/administration/api/reconciliation',
            {'payout_id': id, 'startDate': startDate, 'endDate': endDate, 'reconciledStatus': reconciledStatus});
    }

    static unreconcile(id, startDate, endDate, reconciledStatus) {
        return window.axios.delete('/administration/api/reconciliation/'+id+'?startDate='+startDate+"&endDate="+endDate+"&reconciledStatus="+reconciledStatus);
    }

    static getDetails(id) {
        return window.axios.get('/administration/api/reconciliation/'+id);
    }

    static getReportSummary(startDate, endDate, test) {
        return window.axios.get('/administration/api/reconciliation-report?startDate='+startDate+'&endDate='+endDate+'&test='+test);
    }
}