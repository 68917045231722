<template>
  <div>
    <div v-if="!order.refund">
      <el-row>
        <el-col :span="10">
          <p>Codes to refund count: {{ selected.length }}</p>
          <p v-if="refund">
            Amount to be refunded: ${{ formatPrice(refund.refund) }}
          </p>
        </el-col>
        <span v-if="selected.length > 0  && refund.refund > 0">
          <el-col :span="7">
            <el-button type="info" class="bright-button" @click="partialRefund"
              >Refund Selected</el-button
            >
          </el-col>
        </span>
        <span v-else>
          <el-col :span="7">
            <el-button disabled>Refund Selected</el-button>
          </el-col>
        </span>
        <span v-if="canFullRefund && selected.length == 0">
          <el-col :span="7">
            <el-button type="primary" @click="fullRefund"
              >Refund Entire Order</el-button
            >
          </el-col>
        </span>
        <span v-else>
          <el-col :span="7">
            <el-button type="primary" disabled>Refund Entire Order</el-button>
          </el-col>
        </span>
      </el-row>
      <br />
      <el-table
        :data="order.test_codes"
        border
        :height="400"
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column label="Test Code" property="code"></el-table-column>
        <el-table-column
          label="Created At"
          property="created_at"
        ></el-table-column>
        <el-table-column
          label="Expires"
          property="expires_at"
        ></el-table-column>
        <el-table-column label="Used" property="redeemed_at"></el-table-column>
      </el-table>
    </div>
    <div v-else>
      <el-row>
        <el-col :span="10">
          <p>Codes refunded count: {{ refundedCodeCount }}</p>
          <p>Amount refunded: ${{ formatPrice(order.refund.amount) }}</p>
          <p>Date: {{ order.refund.created_at }}</p>
        </el-col>
      </el-row>
      <br />
      <el-table :data="order.test_codes" border :height="400">
        <el-table-column label="Test Code" property="code"></el-table-column>
        <el-table-column
          label="Created"
          property="created_at"
        ></el-table-column>
        <el-table-column
          label="Expires"
          property="expires_at"
        ></el-table-column>
        <el-table-column label="Used" property="redeemed_at"></el-table-column>
        <el-table-column
          label="Refunded"
          property="refunded_at"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import LoadingIndicator from "vue-spinner/src/BeatLoader.vue";
import { ElTable, ElButton, ElRow, ElCol } from "element-plus";

import httpRefund from "../../http/Refund.js";

export default {
  data() {
    return {
      selected: [],
      refund: null,
    };
  },
  props: ["order"],
  computed: {
    canFullRefund: function () {
      for (let i = 0; i < this.order.test_codes.length; i++) {
        if (!this.selectable(this.order.test_codes[i], i)) {
          return false;
        }
      }
      return true;
    },
    refundedCodeCount: function () {
      return window
        .collect(this.order.test_codes)
        .filter(function (code) {
          console.log("Code: ", code);
          return code.refunded_at;
        })
        .count();
    },
  },
  components: {
    LoadingIndicator,
    ElTable,
    ElButton,
    ElRow,
    ElCol,
  },
  methods: {
    getTestCodeIDs() {
      let codes = [];
      for (let i = 0; i < this.selected.length; i++) {
        codes.push(this.selected[i].id);
      }
      return codes;
    },
    selectionChange(val) {
      this.selected = val;
      if (this.selected.length > 0) {
        httpRefund
          .getRefund(this.order.id, this.getTestCodeIDs())
          .then(({ data }) => {
            this.refund = data;
          });
      } else {
        this.refund = null;
      }
    },
    selectable(ElRow, index) {
      return (
        ElRow.redeemed_at === null && new Date(ElRow.expires_at) > Date.now()
      );
    },
    partialRefund() {
      httpRefund
        .createPartialRefund(this.order.id, this.getTestCodeIDs())
        .then(({ data }) => {
          window.location.reload();
        });
    },
    fullRefund() {
      httpRefund.createFullRefund(this.order.id).then(({ data }) => {
        window.location.reload();
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style scoped>
.bright-button {
  background-color: #657182;
  filter: brightness(1.2);
}

.bright-button:hover {
  filter: brightness(1.2);
}
</style>
