export default {
    data() {
        return {
            validationErrors: {},
        }
    },
    methods: {
        getValidationError(field) {
            if(this.validationErrors[field] && typeof this.validationErrors[field][0] !== 'undefined') {
                return this.validationErrors[field][0];
            }
            return null;
        },
        clearValidationError(field) {
            if(this.validationErrors[field]) {
                this.$delete(this.validationErrors, field);
            }
        },
        clearAllValidationErrors() {
            this.validationErrors = {};
        },
        hasValidationErrors() {
            return !_.isEmpty(this.validationErrors);
        }
    }
}