<template>
  <div>
    <el-row>
      <el-col :span="24"></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
          :data="payoutDetails"
          showSummary
          :summaryMethod="summary"
          maxHeight="457"
          border
        >
          <el-table-column
            prop="type"
            label="Type"
            sortable
            width="100"
            :filters="[
              { text: 'Charge', value: 'charge' },
              { text: 'Refund', value: 'refund' },
              { text: 'Dispute', value: 'dispute' },
            ]"
            :filterMethod="filterTag"
          >
            <template v-slot="{ row }">
              <el-tag :type="tagType(row.type)" closeTransition>{{
                row.type
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="source.orderType" sortable label="Order Type">
          </el-table-column>
          <el-table-column
            prop="amount"
            label="Amount"
            align="right"
            sortable
            :formatter="format"
          ></el-table-column>
          <el-table-column
            prop="source.quantity"
            label="Test Codes"
            sortable
            align="right"
          ></el-table-column>
          <el-table-column
            prop="fee"
            label="Fee"
            align="right"
            sortable
            :formatter="format"
          ></el-table-column>
          <el-table-column
            prop="net"
            label="Net"
            align="right"
            sortable
            :formatter="format"
          ></el-table-column>
          <el-table-column
            prop="tax"
            label="Tax"
            align="right"
            sortable
            :formatter="format"
          ></el-table-column>
          <el-table-column
            prop="source.discount"
            label="Discount"
            align="right"
            sortable
          ></el-table-column>
          <el-table-column label="Order Id" align="right">
            <template v-slot="{ row }">
              <el-button
                size="small"
                @click="handleViewOrder(row.source.orderId)"
                >Order {{ row.source.orderId }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ElTable, ElRow, ElCol } from "element-plus";

export default {
  props: ["payoutDetails", "format"],
  components: {
    ElTable,
    ElRow,
    ElCol,
  },
  methods: {
    filterTag(value, ElRow) {
      return ElRow.type === value;
    },
    tagType(type) {
      if (type === "charge") {
        return "success";
      } else if (type === "dispute") {
        return "warning";
      } else if (type === "refund") {
        return "danger";
      } else {
        return "gray";
      }
    },
    summary(params) {
      const { cols, data } = params;
      const sums = [];

      let amountSum = 0;
      let testCodeSum = 0;
      let feeSum = 0;
      let netSum = 0;

      data.forEach(function (item) {
        if (item.type === "charge") {
          amountSum += item.amount;
          testCodeSum += parseInt(item.source.quantity, 10);
        }
        feeSum += item.fee;
        netSum += item.net;
      });

      sums[0] = "";
      sums[1] = "Totals";
      sums[2] =
        amountSum < 0
          ? "$(" + Math.abs(amountSum).toFixed(2) + ")"
          : "$" + amountSum.toFixed(2);
      sums[3] = testCodeSum;
      sums[4] =
        feeSum < 0
          ? "$(" + Math.abs(feeSum).toFixed(2) + ")"
          : "$" + feeSum.toFixed(2);
      sums[5] =
        netSum < 0
          ? "$(" + Math.abs(netSum).toFixed(2) + ")"
          : "$" + netSum.toFixed(2);

      return sums;
    },
    handleViewOrder(orderId) {
      window.location.href = "/administration/order/" + orderId;
    },
  },
};
</script>
