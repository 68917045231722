<template>
    <div v-if="bounce" class="box well">
        <ul>
            <li><strong>Notification Type:</strong> {{ bounce.notification_type }}</li>
            <li><strong>Bounce Type:</strong> {{ bounce.bounce_type }}</li>
            <li><strong>Bounce Sub Type:</strong> {{ bounce.bounce_sub_type }}</li>
            <li><strong>Email:</strong>
                <a :href="bounce.subscriber_id === null ? '#' :'/administration/subscribers/' + bounce.subscriber.obfuscated_id">
                    {{ bounce.email }}
                </a>
            </li>
            <li><strong>Action:</strong> {{ bounce.action }}</li>
            <li><strong>Status:</strong> {{ bounce.status }}</li>
            <li><strong>Diagnostic Code:</strong> {{ bounce.diagnostic_code }}</li>
            <li><strong>Receieved At:</strong> {{ bounce.created_at }}</li>
        </ul>
    </div>
</template>
<script>
    import httpEnneathought from '../../http/Enneathought';

    export default{
        data(){
            return{
                bounce: null
            }
        },
        props: ['bounceId'],
        methods: {
            fetchBounce() {
                httpEnneathought.getBounce(this.bounceId).then(({data}) => {
                    this.bounce = data;
                });
            },
        },
        mounted() {
            this.fetchBounce();
        }
    }
</script>
