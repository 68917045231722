/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import "./bootstrap";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import AdminEmail from "./vue/components/AdminEmail.vue";
import AdminResendEmail from "./vue/components/AdminResendEmail.vue";
import ReconciliationContainer from "./vue/components/Reconciliation/ReconciliationContainer.vue";
import ReconciliationList from "./vue/components/Reconciliation/ReconciliationList.vue";
import ReconciliationDetail from "./vue/components/Reconciliation/ReconciliationDetail.vue";
import ReconciliationDetailSummary from "./vue/components/Reconciliation/ReconciliationDetailSummary.vue";
import ReportContainer from "./vue/components/PayoutReport/ReportContainer.vue";
import ReportSummary from "./vue/components/PayoutReport/ReportSummary.vue";
import AdminCoupon from "./vue/components/AdminCoupon.vue";
import Refund from "./vue/components/Refund/Refund.vue";
import AddTestCodes from "./vue/components/AddTestCodes.vue";
import OrderForm from "./vue/components/OrderForm.vue";
import OrderFormSpinner from "./vue/components/OrderFormSpinner.vue";
import ReviewCartTable from "./vue/components/ReviewCartTable.vue";
import Cart from "./vue/components/Checkout/Cart.vue";
import Checkout from "./vue/components/Checkout/Checkout.vue";
import CheckoutForm from "./vue/components/Checkout/CheckoutForm.vue";
import BusinessAccount from "./vue/components/Checkout/BusinessAccount.vue";
import TaxExemptForm from "./vue/components/TaxExemptForm.vue";
import ShoppingCartBadge from "./vue/components/ShoppingCartBadge.vue";
import AdminEvent from "./vue/components/AdminEvent.vue";
import SubscriberList from "./vue/components/Enneathought/SubscriberList.vue";
import BounceList from "./vue/components/Enneathought/BounceList.vue";
import SendingStats from "./vue/components/Enneathought/SendingStats.vue";
import SubscriberDetails from "./vue/components/Enneathought/SubscriberDetails.vue";
import BounceDetails from "./vue/components/Enneathought/BounceDetails.vue";
import Modal from "./vue/components/Enneathought/SubscriberDetails.vue";
import PromotionList from "./vue/components/Enneathought/PromotionList.vue";
import EnneathoughtList from "./vue/components/Enneathought/EnneathoughtList.vue";
import TestShow from "./vue/components/Test/Show.vue";
import RhetiQuestion from "./vue/components/Test/Partials/RhetiQuestion.vue";
import IVQQuestion from "./vue/components/Test/Partials/IVQQuestion.vue";
import IVQFinal from "./vue/components/Test/Partials/IVQFinal.vue";
import PartialNotificationWrapper from "./vue/components/Administration/PartialNotificationWrapper.vue";
import PartialNotification from "./vue/components/Administration/PartialNotification.vue";

const app = createApp({});

app.component("admin-email", AdminEmail);
app.component("admin-resend-email", AdminResendEmail);
app.component("reconciliation-container", ReconciliationContainer);
app.component("reconciliation-list", ReconciliationList);
app.component("reconciliation-detail", ReconciliationDetail);
app.component("reconciliation-detail-summary", ReconciliationDetailSummary);
app.component("report-container", ReportContainer);
app.component("report-summary", ReportSummary);
app.component("admin-coupon", AdminCoupon);
app.component("refund", Refund);
app.component("add-test-codes", AddTestCodes);
app.component("order-form", OrderForm);
app.component("order-form-spinner", OrderFormSpinner);
app.component("review-cart-table", ReviewCartTable);
app.component("cart", Cart);
app.component("checkout", Checkout);
app.component("checkout-form", CheckoutForm);
app.component("business-account", BusinessAccount);
app.component("tax-exempt-form", TaxExemptForm);

app.component("shopping-cart-badge", ShoppingCartBadge);

app.component("admin-event", AdminEvent);

app.component("subscriber-list", SubscriberList);
app.component("bounce-list", BounceList);
app.component("sending-stats", SendingStats);
app.component("subscriber-details", SubscriberDetails);
app.component("bounce-details", BounceDetails);
app.component("modal", Modal);
app.component("promotion-list", PromotionList);
app.component("enneathought-list", EnneathoughtList);

app.component("test-show", TestShow);
app.component("rheti-question", RhetiQuestion);
app.component("ivq-question", IVQQuestion);
app.component("ivq-final", IVQFinal);

app.component("partial-notification-wrapper", PartialNotificationWrapper);
app.component("partial-notification", PartialNotification);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

import store from "./vue/stores";

import moment from "moment";
import _ from "lodash";

// configure language
// https://github.com/element-plus/element-plus/discussions/5658
// Element Plus uses English as the default language. To change the default language, please refer to the Global Configuration section and set the locale property.
// See AdminCoupon.vue as well

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import VuePaginate from "vue-paginate";

import { createApp } from "vue";
app.use(VuePaginate);

//new lang element using element plus
app.use(ElementPlus);

//reimporting stores
app.use(store);

app.mount("#app");

app.config.globalProperties.$filters = {
  formatDate(value) {
    return moment(String(value)).format("MM/DD/YYYY");
  },
  formatPrice(input) {
    return "$" + parseFloat(input).toFixed(2);
  },
};

// Handle image processing for npm run build
import.meta.glob(["../images/**"]);
